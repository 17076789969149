import { Box, Typography } from "@mui/material";

const AlphaMiniCard = ({ id, tag, title, percentage, name, image }) => {
  return (
    <Box
      key={id}
      sx={{
        background: "#1B1B1E",
        display: "flex",
        flexDirection: "column",
        padding: "10px 10px",
        borderRadius: "5px",
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: "10px",
            fontWeight: 500,
            color: "#C0D709",
          }}
        >
          {tag}
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", gap: "40px", justifyContent: "space-between" }}
      >
        <Box>
          <Typography sx={{ fontSize: "12px", fontWeight: 700, color: "#fff" }}>
            {title}
          </Typography>
          <Typography
            sx={{ fontSize: "10px", fontWeight: 500, color: "#C0D709" }}
          >
            {percentage}have this
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 700,
              color: "#fff",
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {" "}
            <img src={image} alt="" /> <span>2.5</span>
          </Typography>
          <Typography
            sx={{
              fontSize: "10px",
              fontWeight: 500,
              color: "#C0D709",
              textAlign: "end",
            }}
          >
            {name}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AlphaMiniCard;
