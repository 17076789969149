import { Box, Typography } from "@mui/material";
import Carousel from "react-elastic-carousel";
import { CarouselArrow } from "../../utils";
import SingleCard from "../card/singleCard/index";
import { data } from "./data";
const Slider = ({ title, detail }) => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 },
  ];
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          padding: { lg: 4, md: 2, sm: 0 },
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontSize: "36px",
            fontWeight: 800,
          }}
        >
          <span style={{ color: "#C0D709" }}>{title}</span>&nbsp;
          {detail}
        </Typography>
      </Box>
      <Box>
        <Carousel
          autoPlaySpeed={5000}
          enableAutoPlay
          disableArrowsOnEnd={false}
          breakPoints={breakPoints}
          infinite={true}
          pagination={false}
          renderArrow={(arrowProps) => <CarouselArrow {...arrowProps} />}
          style={{ padding: "0 !important" }}
        >
          {data &&
            data.map((items) => {
              return <SingleCard key={items.id} {...items} />;
            })}
        </Carousel>
      </Box>
    </Box>
  );
};
export default Slider;
