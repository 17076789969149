import { Avatar, Box, Typography } from "@mui/material";
import coverImage from "../../../assets/images/coverImage.png";
import verified from "../../../assets/icons/verified.svg";
import Button from "../../button";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { ShortString } from "../../../utils";
import noProfile from "../../../assets/images/noprofile.png";
import { coverBaseURL, profileBaseURL } from "../../../http/config";
const UserDetails = () => {
  const classes = useStyles();
  const {
    user: { username, bio, cover, img },
  } = useSelector((state) => state.auth);
  return (
    <Box>
      <Box className={classes.imagesContainer}>
        <Box>
          <img
            src={cover === null || "" ? coverImage : coverBaseURL + cover}
            className={classes.image}
            alt=""
          />
        </Box>
        <Box className={classes.avatarContainer}>
          <Avatar
            alt="Profile"
            src={img === null ? noProfile : profileBaseURL + img}
            className={classes.avatar}
          />
        </Box>
      </Box>
      {/* User Informtaion */}
      <ProfileWrapper>
        <Box className={classes.detailContainer}>
          <Box className={classes.details}>
            <Typography className={classes.username}>
              <ShortString address={username} />
            </Typography>
            <img src={verified} alt="verify" />
            <Typography
              sx={{ color: "rgba(255, 255, 255, 0.60)", fontSize: "12px" }}
            >
              Get verified
            </Typography>
          </Box>

          <Box className={classes.buttonContainer}>
            <Typography
              sx={{ color: "rgba(255, 255, 255, 0.60)", fontSize: "19px" }}
            >
              {bio === null ? "Add your bio from edit profile" : bio}
            </Typography>
            <Button
              component={Link}
              to="/editprofile"
              className={classes.button}
              variant="secondaryContained"
            >
              Edit Profile
            </Button>
          </Box>
        </Box>
      </ProfileWrapper>
    </Box>
  );
};
export default UserDetails;
const ProfileWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        paddingLeft: { lg: 2.5, md: 1, sm: 1, xs: 1 },
        paddingRight: { lg: 2.5, md: 1, sm: 1, xs: 1 },
      }}
    >
      {children}
    </Box>
  );
};
const useStyles = makeStyles((theme) => ({
  detailContainer: {
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  avatarContainer: {
    position: "relative",
    bottom: 40,
    left: "1.5%",
    width: "50%",
    [theme.breakpoints.down("md")]: {
      left: "1%",
    },
    [theme.breakpoints.down("sm")]: {
      left: "2%",
    },
  },
  details: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    "& img": {
      width: "25px",
    },
  },
  username: {
    fontSize: "42px !important",
    fontWeight: 700 + "!important",
    textTransform: "capitalize",
  },
  image: {
    width: "100%",
    maxHeight: "300px",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      minHeight: "200px",
    },
  },
  avatar: {
    width: "100px !important",
    height: "100px !important",
    border: "2px solid #E3FF00",
    [theme.breakpoints.down("md")]: {
      width: "80px !important",
      height: "80px !important",
    },
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    width: "40%",
    "& Typography": {
      color: "rgba(255, 255, 255, 0.60) !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  button: {
    width: "130px",
  },
}));
