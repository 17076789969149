import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";
import { Box, CardActionArea } from "@mui/material";
import CartIcon from "../../../assets/icons/cart.png";
import Button from "../../button";
import { ShortString } from "../../../utils";
import { Link } from "react-router-dom";

const SingleCard = ({
  fixedPrice,
  title,
  creatorUsername,
  image,
  imageAlt,
  chainId,
  amountId,
  tokenId,
  onSale,
}) => {
  const classes = useStyles();
  return (
    <Card className={`${classes.card} ${classes.cardHovered}`}>
      <CardActionArea>
        <CardMedia
          component="img"
          image={image}
          width="200"
          height="200"
          alt={imageAlt}
          className={classes.cardMedia}
        />
        <CardContent sx={{ padding: "10px !important" }}>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Typography gutterBottom variant="h6">
                {title}
              </Typography>
              <Typography variant="caption">
                {" "}
                <ShortString address={creatorUsername} />{" "}
              </Typography>
            </Stack>
            <Box display="flex">
              <Typography variant="body1" color="#C7D921">
                {fixedPrice}&nbsp;ETH
              </Typography>
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
      <CardActions className={classes.cardActions}>
        {onSale === 0 ? (
          <Button
            variant="contained"
            className={classes.button}
            component={Link}
            to={`/listing/${tokenId}/${amountId}/${chainId}`}
          >
            List Now
          </Button>
        ) : (
          <Button variant="contained" className={classes.button}>
            Listed
          </Button>
        )}

        <img
          src={CartIcon}
          width="60px"
          height="40px"
          style={{
            color: "white !impotant",
            padding: "0 !important",
          }}
          alt="Cart"
        />
      </CardActions>
    </Card>
  );
};

export default SingleCard;
const useStyles = makeStyles((theme) => ({
  card: {
    padding: "1rem !important",
    borderRadius: "1.5rem !important",
    backgroundColor: "#333431 !important",
    color: "white !important",
    overflow: "hidden !important",
    transition: "height 3s !important",
    width: "20vw !important",
    [theme.breakpoints.down("md")]: {
      width: "70vw !important",
    },
    [theme.breakpoints.down("sm")]: {
      width: "70vw !important",
    },
    // "&:hover": {
    //   maxHeight: "400px",
    //   "& .MuiCardActions-root": {
    //     display: "flex !important",
    //     maxHeight: "400px !important",
    //   },
    // },
  },

  cardMedia: {
    borderRadius: "2rem",
    aspectRatio: "3/2",
    objectFit: "contain",
    minHeight: "20vh !important",
  },
  cardIcon: {
    color: "white !important",
    padding: 0 + "!important",
    marginLeft: 5,
  },
  cardActions: {
    display: "flex !important",
    justifyContent: "space-between",
    padding: "10px 0 !important",
  },
  button: {
    width: "100% !important",
    padding: "0px !important",
    height: "35px !important",
    fontWeight: "600 !important",
  },
}));
