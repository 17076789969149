import { nftHunters } from "../../http/config";

//Get All Arts
export const getAllArtsApi = async (payload) => {
  try {
    const res = await nftHunters.get("getArts", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
// get single Arts
export const getSingleArtsApi = async ({ tokenId, amountId, chainId }) => {
  try {
    const res = await nftHunters.get(
      `singleArt/${tokenId}/${amountId}/${chainId}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};

//Art History API

export const getArtHistoryApi = async ({ tokenId, amountId, chainId }) => {
  try {
    const res = await nftHunters.get(
      `nftHistory/${tokenId}/${amountId}/${chainId}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
};
