import { Box, Typography, useMediaQuery } from "@mui/material";
import bannerImage from "../../assets/images/bannerImage.png";
import { makeStyles } from "@mui/styles";
import Button from "../button";
import RocketSvg from "../../assets/icons/rocket.svg";
import logo from "../../assets/images/logo.png";
const Banner = () => {
  const classes = useStyle();
  const isMobile = useMediaQuery("(min-width:800px)");
  const isNotMobile = useMediaQuery("(max-width:800px)");
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.rightContainer}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "start",
          }}
        >
          <img src={logo} alt="/" />
        </Box>
        <Box className={classes.headingContainer}>
          <Typography className={classes.heading}>
            Explore & Acquire Your{" "}
            <span className={classes.span}>Next Digital</span> Assets
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" color="white">
            Enjoy Your NFT Journey on the leading Multi-Chain <br /> MarketPlace
          </Typography>
        </Box>
        {isNotMobile && (
          <Box>
            <img src={bannerImage} width="100%" alt="" />
          </Box>
        )}
        <Box className={classes.auctionContainer}>
          <Box>
            <Button
              startIcon={<img src={RocketSvg} width={20} alt="Rocket" />}
              variant="contained"
            >
              Get Started
            </Button>
          </Box>
          <Box sx={{ display: "flex", gap: "40px" }}>
            <Box>
              <Typography className={classes.inNumber}>240k+</Typography>
              <Typography>Total Sale</Typography>
            </Box>
            <Box>
              <Typography className={classes.inNumber}>100k+</Typography>
              <Typography>Auctions</Typography>
            </Box>{" "}
            <Box>
              <Typography className={classes.inNumber}>240k+</Typography>
              <Typography>Auctions</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {isMobile && (
        <Box>
          <img src={bannerImage} width="500px" height={"600px"} alt="" />
        </Box>
      )}
    </Box>
  );
};
export default Banner;
const useStyle = makeStyles((theme) => ({
  mainContainer: {
    display: "grid",
    gridTemplateColumns: "1.2fr 1fr",
    padding: "10px 0px",
    gap: 10,
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      justifyContent: "center",
      padding: "10px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: "0px 0px 20px 0px",
    },
  },

  span: {
    color: "#C7D921",
  },
  heading: {
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    fontWeight: 700 + "!important",
    fontStyle: "normal !important",
    color: "white",
    fontSize: "40px !important",
  },
  headingContainer: {
    width: "400px !important",
    [theme.breakpoints.down("md")]: {
      width: "100% !important",
    },
  },
  auctionContainer: {
    color: "#fff",
    display: "flex",
    gap: 30,
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  inNumber: {
    fontSize: "28px !important",
    fontWeight: 700 + "!important",
    textTransform: "capitalize !important",
  },
  rightContainer: {
    padding: "0px 10px ",
    display: "flex",
    flexDirection: "column",
    gap: 30,
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      padding: "20px 0px",
    },
  },
}));
