import { Box } from "@mui/material";

import React from "react";
import { CustomTabs, Tabs } from "../editCustomTabs";
import EditCoverImage from "../editCover";
import { useFormik } from "formik";
import UserInformation from "../editUserInformation";
import DeleteUser from "../deleteUser";
import UploadProfile from "../uploadProfile";
import EditTabPanel from "../editTabPanel";
import { useDispatch, useSelector } from "react-redux";
import { updateInfo } from "../../../redux/profile/profile.action";
import { useAddress } from "@thirdweb-dev/react";

const EditprofileTabs = () => {
  const [value, setValue] = React.useState(0);
  const tabs = ["Profile", "Account"];
  const dispatch = useDispatch();
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const wallet = useAddress();
  const {
    user: { firstName, username, email, bio, instagram, twitter, lastName },
  } = useSelector((state) => state.auth);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: username,
      firstName: firstName,
      lastName: lastName,
      bio: bio,
      instagram: instagram,
      twitter: twitter,
      email: email,
      walletAddress: wallet,
    },
    onSubmit: (values) => {
      dispatch(updateInfo(values));
    },
  });
  console.log(formik.values);
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { lg: "1fr 4fr", md: "1fr 5fr", sm: "1fr" },
      }}
    >
      <Tabs value={value} onChange={handleChange}>
        {tabs.map((items) => (
          <CustomTabs label={items} />
        ))}
      </Tabs>

      <Box>
        <EditTabPanel value={value} index={0}>
          <Box position={"relative"}>
            <Box>
              <EditCoverImage />
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: "-15%", // Adjust as needed
                left: "2%", // Adjust as needed
              }}
            >
              {" "}
              <UploadProfile />
            </Box>
          </Box>
          <Box pt={5}>
            <UserInformation formik={formik} />
          </Box>
        </EditTabPanel>
        <EditTabPanel value={value} index={1}>
          <Box
            sx={{
              minHeight: "70vh",
              padding: {
                lg: "20px 30px",
                md: "20px 30px",
                sm: "10px 10px",
                xs: "10px 10px",
              },
            }}
          >
            <DeleteUser />
          </Box>
        </EditTabPanel>
      </Box>
    </Box>
  );
};

export default EditprofileTabs;
