import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#0F0F0F",
    padding: "40px 20px",
  },
  miniCardContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "47%",
    gap: "10px",
    paddingTop: "20px",

    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  alphaCardHeading: {
    fontSize: "35px !important",
    fontWeight: 400 + "!important",
    color: "#fff",

    "& span": {
      color: "#E3FF00",
    },
  },
}));
