import { Box, Typography } from "@mui/material";
import Button from "../button";

const CartPurchase = () => {
  return (
    <Box sx={{ padding: "5px 10px" }}>
      <Box>
        <Typography sx={{ fontSize: "16px", fontWeight: 700, color: "#fff" }}>
          Total Price
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "end",
          padding: "5px 10px",
        }}
      >
        <Typography sx={{ fontSize: "12px", fontWeight: 800, color: "#fff" }}>
          15.7 ETH
        </Typography>
        <Typography sx={{ fontSize: "12px", fontWeight: 400, color: "#fff" }}>
          $39,135,39
        </Typography>
      </Box>

      <Box py={1} sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="contained"> Complete purchase</Button>
      </Box>
    </Box>
  );
};
export default CartPurchase;
