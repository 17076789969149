import { Box, CircularProgress } from "@mui/material";
import UserDetails from "../../components/userProfile/userDetails";
import { useStyles } from "./style";
import ProfileTabs from "../../components/porfileTabs";
import { useDispatch, useSelector } from "react-redux";
import { inWallet as userCollection } from "../../redux/profile/profile.action";
import { useAddress } from "@thirdweb-dev/react";
import React from "react";

const UserProfile = () => {
  const classes = useStyles();
  const wallet = useAddress();
  const dispatch = useDispatch();
  const {
    inWallet: { createdArts, collectedArts, createdCollections },
    isInWalletLoading,
  } = useSelector((state) => state.profile);

  React.useEffect(() => {
    dispatch(userCollection(wallet));
  }, [dispatch, wallet]);
  return (
    <Box className={classes.mainContainer}>
      <Box>
        <UserDetails />
      </Box>
      {isInWalletLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box py={3}>
          <ProfileTabs
            createdArts={createdArts}
            collectedArts={collectedArts}
            createdCollections={createdCollections}
          />
        </Box>
      )}
    </Box>
  );
};
export default UserProfile;
