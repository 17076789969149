import { combineReducers } from "@reduxjs/toolkit";
import authSlicer from "../redux/auth/auth.slicer";
import artsSlice from "../redux/arts/arts.slice";
import profileSlice from "../redux/profile/profile.slice";
import mintSlicer from "../redux/mint/mint.slicer";
import putOnFixedPriceSlicer from "../redux/listing/listing.slicer";
import directTransferSlicer from "../redux/transfer/transfer.slicer";
const rootReducer = combineReducers({
  auth: authSlicer,
  arts: artsSlice,
  profile: profileSlice,
  mint: mintSlicer,
  putOnFixedPrice: putOnFixedPriceSlicer,
  transfer: directTransferSlicer,
});

export default rootReducer;
