import { Button as MuiButton } from "@mui/material";
import { withStyles } from "@mui/styles";

const Button = withStyles((theme) => ({
  root: {
    borderRadius: 5,
    color: theme.palette.text.primary + " !important",
    fontSize: "16px !important",
    boxShadow: "none !important",
  },
  secondaryContained: {
    fontFamily: "Roboto !important",
    background: "#C0D709 !important",
    fontWeight: 700 + " !important",
    letterSpacing: "normal",
    textTransform: "capitalize !important",
    color: "#000 !important",
    fontSize: "16px !important",
    height: "40px !important",
    borderRadius: 0 + "!important",
  },
  contained: {
    fontFamily: "Roboto !important",
    background: "#C0D709 !important",
    fontWeight: 700 + " !important",
    letterSpacing: "normal",
    textTransform: "capitalize !important",
    color: "#000 !important",
    fontSize: "16px !important",
    borderRadius: "10px !important",
    height: "40px !important",
  },
  secondaryOutlined: {
    border: "2px solid #E3FF00 !important",
    borderRadius: "10px !important",
    textTransform: "capitalize !important",
    color: "#E3FF00 !important",
  },
  outlined: {
    position: "relative",
    fontWeight: 600 + " !important",
    border: "none !important",
    "&:before": {
      content: "''",
      padding: "2px",
      borderRadius: 5,
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      background: "rgba(255, 255, 255, 0.04)",
      "-webkit-mask":
        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
      "-webkit-mask-composite": "destination-out",
    },
  },
}))(MuiButton);

export default Button;
