import { Box } from "@mui/material";

const Wrapper = ({ children }) => {
  return (
    <Box
      sx={{
        paddingLeft: { lg: 2.5, md: 1, sm: 1, xs: 1 },
        paddingRight: { lg: 2.5, md: 1, sm: 1, xs: 1 },
      }}
    >
      {children}
    </Box>
  );
};
export default Wrapper;
