import image from "../../assets/icons/adidas.png";
import alphaCardIcon from "../../assets/icons/alphaCardIcon.png";
export const data = [
  {
    id: 1,
    tag: "background",
    title: "Sky",
    percentage: "944 (12.1%)",
    name: "floor",
    image: image,
  },
  {
    id: 2,
    tag: "background",
    title: "Sky",
    percentage: "944 (12.1%)",
    name: "floor",
    image: image,
  },
  {
    id: 3,
    tag: "background",
    title: "Sky",
    percentage: "944 (12.1%)",
    name: "floor",
    image: image,
  },
  {
    id: 4,
    tag: "background",
    title: "Sky",
    percentage: "944 (12.1%)",
    name: "floor",
    image: image,
  },
  {
    id: 5,
    tag: "background",
    title: "Sky",
    percentage: "944 (12.1%)",
    name: "floor",
    image: image,
  },
  {
    id: 6,
    tag: "background",
    title: "Sky",
    percentage: "944 (12.1%)",
    name: "floor",
    image: image,
  },
  {
    id: 7,
    tag: "background",
    title: "Sky",
    percentage: "944 (12.1%)",
    name: "floor",
    image: image,
  },
];

export const dataTwo = [
  {
    id: 1,
    image: alphaCardIcon,
    title: "The Alpha Pack #4607",
    price: "2.5",
  },
  {
    id: 2,
    image: alphaCardIcon,
    title: "The Alpha Pack #4607",
    price: "2.5",
  },
  {
    id: 3,
    image: alphaCardIcon,
    title: "The Alpha Pack #4607",
    price: "2.5",
  },
  {
    id: 4,
    image: alphaCardIcon,
    title: "The Alpha Pack #4607",
    price: "2.5",
  },
  {
    id: 5,
    image: alphaCardIcon,
    title: "The Alpha Pack #4607",
    price: "2.5",
  },
];
