import nftImage1 from "../../assets/images/Rectangle 36 (1).png";
import nftImage2 from "../../assets/images/Rectangle 36 (3).png";
import nftImage3 from "../../assets/images/Rectangle 36 (2).png";
import nftImage4 from "../../assets/images/Rectangle 36.png";
import nftImage5 from "../../assets/images/5c3003bf78b1102fc91941318667db17.png";

export const data = [
  {
    id: 1,
    nftName: "Inception",
    userName: "@shakiran",
    imageUrl: nftImage1,
    imageAlt: "Error",
    price: 0.003,
    date: "2022-02-28T14:30:00",
  },
  {
    id: 2,
    nftName: "Azuki",
    userName: "@shakiran",
    imageUrl: nftImage2,
    imageAlt: "Error",
    price: 0.001,
    date: "2021-02-28T14:30:00",
  },
  {
    id: 3,
    nftName: "Pudgy",
    userName: "@shakiran",
    imageUrl: nftImage3,
    imageAlt: "Error",
    price: 0.001,
    date: "2024-02-28T14:30:00",
  },
  {
    id: 4,
    nftName: "TENJIN",
    userName: "@shakiran",
    imageUrl: nftImage4,
    imageAlt: "Error",
    price: "0.001",
    date: "2023-02-28T14:30:00",
  },
  {
    id: 5,
    nftName: "TENJIN",
    userName: "@shakiran",
    imageUrl: nftImage5,
    imageAlt: "Error",
    price: 0.01,
    date: "2022-04-28T14:30:00",
  },
  {
    id: 6,
    nftName: "MURSAL",
    userName: "@shakiran",
    imageUrl: nftImage4,
    imageAlt: "Error",
    price: 0.01,
    date: "2020-02-28T14:30:00",
  },
];
