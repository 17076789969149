import { createSlice } from "@reduxjs/toolkit";
import { login } from "./auth.action";

const initialState = {
  isAuthenticated: false,
  isAuthenticationLoading: false,
  isAuthenticationFailed: false,
  user: {},
};
const authSuccess = (state, action) => {
  state.isAuthenticationLoading = false;
  state.isAuthenticated = true;
  state.user = action.payload;
};
const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetStateAuth: (state) => {
      state.isAuthenticated = false;
      state.isAuthenticationLoading = false;
      state.user = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isAuthenticationLoading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      authSuccess(state, action);
    });
    builder.addCase(login.rejected, (state) => {
      state.isAuthenticationLoading = false;
      state.isAuthenticationFailed = true;
    });
  },
});

export default auth.reducer;
export const { resetStateAuth } = auth.actions;
