import { Box, Typography } from "@mui/material";
import Button from "../../components/button";
import { Link } from "react-router-dom";
const Error404 = () => {
  return (
    <Box
      sx={{
        display: "grid",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        pt: 15,
        pb: 5,
        bgcolor: "#0F0F0F",
        height: "calc(100vh)",
      }}
    >
      <Box>
        <Typography variant="h1" sx={{ color: "#fff", fontWeight: 900 }}>
          4
          <span
            style={{
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "#C7D921",
            }}
          >
            0
          </span>
          4
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5" sx={{ color: "#fff" }}>
          The page you are looking for might have been removed had its <br />
          name changed or is temporarily unavaible
        </Typography>
      </Box>
      <Box sx={{ pt: 5 }}>
        <Button variant="secondaryContained" component={Link} to="/">
          <Typography variant="h6">Home</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Error404;
