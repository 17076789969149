import { Box, CircularProgress, InputBase, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useCreateDirectListing,
  useContract,
  useAddress,
  useContractWrite,
  useContractRead,
  useChainId,
} from "@thirdweb-dev/react";
import {
  marketplaceContractAddress,
  marketplaceContractType,
  nftContractAddress,
  nftContractType,
} from "../../hooks/thirdwebHooks/contractAddresses";
import Button from "../button";

import { useDispatch } from "react-redux";
import { putOnFixedPrice } from "../../redux/listing/listing.action";
const FixPriceForm = ({ tokenId, chainId, ownerWallet, amountId }) => {
  const dispatch = useDispatch();
  const wallet = useAddress();
  const classes = useStyles();
  const chainid = useChainId();
  console.log(chainId, chainid);
  const { contract: marketplace } = useContract(
    marketplaceContractAddress,
    marketplaceContractType
  );
  const { contract: nftCollection } = useContract(
    nftContractAddress,
    nftContractType
  );
  const { data } = useContractRead(nftCollection, "isApprovedForAll", [
    wallet,
    marketplaceContractAddress,
  ]);
  const { mutateAsync: setApprovalForAll } = useContractWrite(
    nftCollection,
    "setApprovalForAll"
  );
  const call = async () => {
    try {
      const approvaldata = await setApprovalForAll({
        args: [marketplaceContractAddress, true],
      });
      console.info("contract call successs", approvaldata);
    } catch (err) {
      console.error("contract call failure", err);
    }
  };

  const { mutateAsync: createDirectListing, isLoading } =
    useCreateDirectListing(marketplace);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      price: "",
      tokenId: tokenId,
      orderId: "",
      transactionHash: "",
      ownerWallet: ownerWallet,
      amountId: amountId,
      chainId: chainId,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      if (!data) {
        call();
      }
      try {
        const txResult = await createDirectListing({
          assetContractAddress: nftContractAddress,
          tokenId: values.tokenId,
          pricePerToken: values.price,
        });
        const txh = txResult.receipt.transactionHash;
        const orderId = parseInt(txResult.id, 16);
        console.log(orderId, "orderid");
        const payload = {
          orderId: orderId,
          tokenId: values.tokenId,
          amountId: 1,
          chainId: values.chainId,
          transactionHash: txh,
          ownerWallet: ownerWallet,
          price: values.price,
        };
        dispatch(putOnFixedPrice(payload));
      } catch (err) {
        console.log(err.message);
      }
    },
  });

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box pt={2}>
          <InputBase
            sx={{ backgroundColor: "#1E1C1F !important" }}
            placeholder="eg. Enter your price"
            className={classes.nameInput}
            name="price"
            type="float"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.price && formik.errors.price ? (
            <Typography style={{ color: "red", fontSize: "14px", pl: 2 }}>
              {formik.errors.price}
            </Typography>
          ) : null}
        </Box>
        <Box pt={4}>
          {" "}
          <Button
            variant="contained"
            type="submit"
            sx={{
              borderRadius: "2px !important",
              background: "rgba(120, 121, 241, 0.33)",
              border: "1.5px solid #7879F1",
              padding: "10px 40px !important",
              width: "100% !important",
            }}
          >
            {isLoading ? (
              <CircularProgress size={"16px"} sx={{ color: "#000" }} />
            ) : (
              "List Nft"
            )}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default FixPriceForm;
const useStyles = makeStyles((theme) => ({
  nameInput: {
    background: "#1E1C1F",
    color: "#7C7C7C !important",
    fontSize: "18px !important",
    fontWeight: 400 + "!important",
    padding: 10,
    borderRadius: "10px !important",
    width: "100% !important",
  },
}));
const validationSchema = Yup.object().shape({
  price: Yup.string().required("Please enter a fix price"),
});
