import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { Button, Typography } from "@mui/material";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import MyCarts from "../myCart";
import { data } from "./data";
import CartPurchase from "../cartPurchase";

export default function TemporaryDrawer({ toggleDrawer, cartOpen }) {
  const DrawerList = (
    <Box
      sx={{
        width: 370,
        backgroundColor: "rgba(34, 36, 26, 0.99)",
      }}
      role="presentation"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "5px 10px",
        }}
      >
        <Typography
          sx={{
            color: "#fff",
            fontSize: "16px",
            fontWeight: 600,
            display: "flex",
            gap: 0.5,
            alignItems: "center",
          }}
        >
          Your Cart <ReportGmailerrorredIcon fontSize="15px" />
        </Typography>
        <Button
          onClick={toggleDrawer(false)}
          sx={{
            fontSize: "12px",
            fontWeight: 400,
            color: "#fff",
            textTransform: "capitalize",
          }}
        >
          Close
        </Button>
      </Box>
      <Divider sx={{ background: "rgba(255, 255, 255, 0.08)" }} />
      <Box>
        <Box
          sx={{
            padding: "5px 15px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ color: "#fff", fontSize: "16px", fontWeight: 600 }}>
            {data.length}&nbsp;items
          </Typography>
          <Button
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: "#fff",
              textTransform: "capitalize",
            }}
          >
            Clear all
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "10px 10px 10px 5px",
          }}
        >
          {data &&
            data.map((data, index) => {
              return <MyCarts key={index} {...data} />;
            })}
        </Box>
      </Box>
      <Divider sx={{ background: "rgba(255, 255, 255, 0.08)" }} />
      <Box>
        <CartPurchase />
      </Box>
    </Box>
  );

  return (
    <div style={{ background: "green" }}>
      <Drawer
        open={cartOpen}
        onClose={toggleDrawer(false)}
        anchor="top right"
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "transparent",
            position: "fixed",
            top: "75px",
            right: 15,
            transition: "right 0.3s ease",
            height: "85%",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              background: "rgba(255, 255, 255, 0.18)",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#B4CA0A",
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}
