import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/home";
import Layout from "../components/layout";
import Error404 from "../pages/404";
import Create from "../pages/create";
import SingleMinting from "../pages/minting/singleMinting";
import CollectionMinting from "../pages/minting/collectionMinting";
import UserProfile from "../pages/userProfile";
import Browse from "../pages/browse";
import ArtDetail from "../pages/artDetail";
import EditProfile from "../pages/editProfile";
import Blog from "../pages/blog";
import TermsAndService from "../pages/termsAndService";
import Listing from "../pages/listing";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      { path: "/create", element: <Create /> },
      { path: "/singleMinting", element: <SingleMinting /> },
      { path: "/collectionMinting", element: <CollectionMinting /> },
      { path: "/userprofile", element: <UserProfile /> },
      { path: "/browse", element: <Browse /> },
      { path: "/:tokenId/:amountId/:chainId", element: <ArtDetail /> },
      { path: "/listing/:tokenId/:amountId/:chainId", element: <Listing /> },

      { path: "/editprofile", element: <EditProfile /> },
      { path: "/blog", element: <Blog /> },
      { path: "/termsandservice", element: <TermsAndService /> },
    ],
  },

  { path: "*", element: <Error404 /> },
]);

export default router;
