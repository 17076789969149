import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import TerminatorImg from "../../assets/Avatar/terminator.png";
import HeadzImg from "../../assets/images/flaghead.png";
import Logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.footerContainer}>
      <Box textAlign={"center"}>
        <Typography className={classes.heading}>Eco-System</Typography>
      </Box>
      <Box className={classes.innerContainer} py={1}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
            NFT Hunters
          </Typography>
          <img src={Logo} width={"50px"} alt="" />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography sx={{ fontSize: "16px", fontWeight: 400 }}>
            HeadHunters
          </Typography>
          <img
            src={TerminatorImg}
            style={{ objectFit: "contain" }}
            width={"30px"}
            alt=""
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography>Terminator</Typography>
          <img src={HeadzImg} width={"60px"} alt="" />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {" "}
          <Typography>Headz</Typography>
          <img src={Logo} width={"50px"} alt="" />
        </Box>
      </Box>
      <hr />
      <Box className={classes.lastContainer} pt={1}>
        <Box>
          <Typography
            sx={{ fontSize: "12px", fontWeight: 500, color: "#f1f1f1" }}
          >
            © 2018 - 2024 Ozone Networks, Inc
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: { lg: 3, md: 2, sm: 1, xs: 0.5 } }}>
          <Typography className={classes.links}>Privacy Policy</Typography>
          <Typography
            className={classes.links}
            component={Link}
            to="/termsandservice"
          >
            Terms of Service
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default Footer;

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    background: "#272727",
    color: "#FFFFFF",
    padding: "2rem 1rem",
    backdropFilter: "blur(25px)",
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px",
    },
  },
  heading: {
    fontSize: "30px !important",
    fontWeight: 400 + "!important",
  },
  innerContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
  },
  lastContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
  },
  links: {
    fontSize: "12px !important",
    fontWeight: 500 + "!important",
    color: "#f1f1f1",
    cursor: "pointer",
    textDecoration: "none",
  },
}));
