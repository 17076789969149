import nftImage1 from "../../../assets/images/Rectangle 36 (1).png";
import nftImage2 from "../../../assets/images/Rectangle 36 (2).png";
import nftImage3 from "../../../assets/images/Rectangle 36 (3).png";
import nftImage4 from "../../../assets/images/Rectangle 36.png";

export const tableHeadData = [
  { id: "id", label: "#" },
  { id: "collection", label: "Collection", align: "left" },
  {
    id: "floorPrice",
    label: "floor Price",
    align: "right",
  },
  {
    id: "floorChange",
    label: "floor Change",
    align: "right",
  },
  {
    id: "volume",
    label: "volume",
    align: "right",
  },
  {
    id: "volumeChange",
    label: "volume Change",
    align: "right",
  },
  {
    id: "items",
    label: "items",
    align: "right",
  },
  {
    id: "owners",
    label: "owners",
    align: "right",
  },
];

export const TableBodyData = [
  {
    id: 1,
    nftName: "MutantApeYachtClub",
    userName: "@shakiran@gmail.com",
    imageUrl: nftImage1,
    imageAlt: "Error",
    floorPrice: 4.31,
    floorChange: 2.6,
    volume: 1181,
    volumeChange: 12.6,
    items: "19.5K",
    owners: "11.6K",
  },
  {
    id: 2,
    nftName: "MutantApeYachtClub",
    userName: "@shakiran@gmail.com",
    imageUrl: nftImage2,
    imageAlt: "Error",
    floorPrice: 4.31,
    floorChange: -2.6,
    volume: 1181,
    volumeChange: -12.6,
    items: "19.5K",
    owners: "11.6K",
  },
  {
    id: 3,
    nftName: "MutantApeYachtClub",
    userName: "@shakiran@gmail.com",
    imageUrl: nftImage3,
    imageAlt: "Error",
    floorPrice: 4.31,
    floorChange: 2.6,
    volume: 1181,
    volumeChange: 12.6,
    items: "19.5K",
    owners: "11.6K",
  },
  {
    id: 4,
    nftName: "MutantApeYachtClub",
    userName: "@shakiran@gmail.com",
    imageUrl: nftImage4,
    imageAlt: "Error",
    floorPrice: 4.31,
    floorChange: -2.6,
    volume: 1181,
    volumeChange: -12.6,
    items: "19.5K",
    owners: "11.6K",
  },
  {
    id: 5,
    nftName: "MutantApeYachtClub",
    userName: "@shakiran@gmail.com",
    imageUrl: nftImage4,
    imageAlt: "Error",
    floorPrice: 4.31,
    floorChange: -2.6,
    volume: 1181,
    volumeChange: -12.6,
    items: "19.5K",
    owners: "11.6K",
  },
  {
    id: 6,
    nftName: "MutantApeYachtClub",
    userName: "@shakiran@gmail.com",
    imageUrl: nftImage4,
    imageAlt: "Error",
    floorPrice: 4.31,
    floorChange: -2.6,
    volume: 1181,
    volumeChange: -12.6,
    items: "19.5K",
    owners: "11.6K",
  },
  {
    id: 7,
    nftName: "MutantApeYachtClub",
    userName: "@shakiran@gmail.com",
    imageUrl: nftImage4,
    imageAlt: "Error",
    floorPrice: 4.31,
    floorChange: -2.6,
    volume: 1181,
    volumeChange: -12.6,
    items: "19.5K",
    owners: "11.6K",
  },
  {
    id: 8,
    nftName: "MutantApeYachtClub",
    userName: "@shakiran@gmail.com",
    imageUrl: nftImage4,
    imageAlt: "Error",
    floorPrice: 4.31,
    floorChange: -2.6,
    volume: 1181,
    volumeChange: -12.6,
    items: "19.5K",
    owners: "11.6K",
  },
  {
    id: 9,
    nftName: "MutantApeYachtClub",
    userName: "@shakiran@gmail.com",
    imageUrl: nftImage4,
    imageAlt: "Error",
    floorPrice: 4.31,
    floorChange: -2.6,
    volume: 1181,
    volumeChange: -12.6,
    items: "19.5K",
    owners: "11.6K",
  },
  {
    id: 10,
    nftName: "MutantApeYachtClub",
    userName: "@shakiran@gmail.com",
    imageUrl: nftImage4,
    imageAlt: "Error",
    floorPrice: 4.31,
    floorChange: -2.6,
    volume: 1181,
    volumeChange: -12.6,
    items: "19.5K",
    owners: "11.6K",
  },
  {
    id: 11,
    nftName: "MutantApeYachtClub",
    userName: "@shakiran@gmail.com",
    imageUrl: nftImage4,
    imageAlt: "Error",
    floorPrice: 4.31,
    floorChange: -2.6,
    volume: 1181,
    volumeChange: -12.6,
    items: "19.5K",
    owners: "11.6K",
  },
  {
    id: 12,
    nftName: "MutantApeYachtClub",
    userName: "@shakiran@gmail.com",
    imageUrl: nftImage4,
    imageAlt: "Error",
    floorPrice: 4.31,
    floorChange: -2.6,
    volume: 1181,
    volumeChange: -12.6,
    items: "19.5K",
    owners: "11.6K",
  },
  {
    id: 13,
    nftName: "MutantApeYachtClub",
    userName: "@shakiran@gmail.com",
    imageUrl: nftImage4,
    imageAlt: "Error",
    floorPrice: 4.31,
    floorChange: -2.6,
    volume: 1181,
    volumeChange: -12.6,
    items: "19.5K",
    owners: "11.6K",
  },
  {
    id: 14,
    nftName: "MutantApeYachtClub",
    userName: "@shakiran@gmail.com",
    imageUrl: nftImage4,
    imageAlt: "Error",
    floorPrice: 4.31,
    floorChange: -2.6,
    volume: 1181,
    volumeChange: -12.6,
    items: "19.5K",
    owners: "11.6K",
  },
  {
    id: 15,
    nftName: "MutantApeYachtClub",
    userName: "@shakiran@gmail.com",
    imageUrl: nftImage4,
    imageAlt: "Error",
    floorPrice: 4.31,
    floorChange: -2.6,
    volume: 1181,
    volumeChange: -12.6,
    items: "19.5K",
    owners: "11.6K",
  },
  {
    id: 16,
    nftName: "MutantApeYachtClub",
    userName: "@shakiran@gmail.com",
    imageUrl: nftImage4,
    imageAlt: "Error",
    floorPrice: 4.31,
    floorChange: -2.6,
    volume: 1181,
    volumeChange: -12.6,
    items: "19.5K",
    owners: "11.6K",
  },
];
