import { Box } from "@mui/material";

import Banner from "../../components/banner";
import Slider from "../../components/Slider";
import CollectionCard from "../../components/card/collectionCard/mappingCard";
import Wrapper from "../../components/wrapper/index";
import TrendingCard from "../../components/card/trendingCard/mapTrending";
import backgroundImage from "../../assets/images/backgroungImage.png";
import FAQ from "../../components/faq";
import { TableContainer } from "../../components/table/TableContainer";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getAllArts } from "../../redux/arts/arts.action";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllArts());
  }, [dispatch]);
  return (
    <Box>
      {/* Banner and Collection Card */}
      {/* <Notification /> */}
      <Box
        sx={{
          background: "linear-gradient(62deg, #E3FF00 -108.63%, #000 40.25%);",
        }}
        pb={4}
      >
        <Wrapper>
          <Box>
            <Banner />
          </Box>
        </Wrapper>
        <Box>
          <CollectionCard />
        </Box>
        <Wrapper>
          <Box pt={4}>
            <TableContainer />
          </Box>
        </Wrapper>
      </Box>
      {/* Slider Cards */}
      <Box
        sx={{
          backgroundImage: `url(${backgroundImage})`,
          backgroundColor: "black",
          zIndex: 1000,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Box pt={10}>
          <Slider
            title="Featured"
            detail="Collections on
          PulseChain"
          />
        </Box>
        <Box pt={10}>
          <Slider title="PulseChain" detail="Collections" />
        </Box>{" "}
        <Box pt={10}>
          <Slider title="Ethereum" detail="Collections" />
        </Box>{" "}
        <Box pt={10}>
          <Slider title="BSC" detail="Collections" />
        </Box>
        <Box pt={10}>
          <Slider title="Polygon" detail="Collections" />
        </Box>
        <Box pt={10}>
          <Slider title="Avalanche" detail="Collections" />
        </Box>
        <Box pt={10} pb={10}>
          <Slider title="Optimism" detail="Collections" />
        </Box>
      </Box>
      {/* Trending Cards  */}

      <Box
        sx={{
          background: "linear-gradient(62deg, #E3FF00 -108.63%, #000 40.25%);",
        }}
      >
        <TrendingCard />
      </Box>

      <Box
        sx={{
          background: "#0F0F0F",
          paddingBottom: "50px",
        }}
      >
        <FAQ />
      </Box>
      <Box
        px={40}
        sx={{ background: "black !important", width: "100% !important" }}
      ></Box>
    </Box>
  );
};
export default Home;
