import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { makeStyles } from "@mui/styles";
import cn from "classnames";

export const CarouselArrow = ({ type, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper} onClick={onClick}>
      <button className={classes.leftChevron}>
        <ArrowForwardIosIcon
          className={cn({
            [classes.arrowRight]: type === "NEXT",
          })}
        />
      </button>
    </div>
  );
};

export const BuyCrowselArrows = ({ type, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper} onClick={onClick}>
      <button className={classes.leftChevron}>
        <ArrowForwardIosIcon
          className={cn({
            [classes.arrowRight]: type === "NEXT",
          })}
        />
      </button>
    </div>
  );
};

export const ShortString = ({ address }) => {
  const ethereumAddressRegex = /^0x[a-fA-F0-9]{40}$/;
  const wallet = ethereumAddressRegex.test(address);
  if (wallet) {
    if (address.length < 6) {
      return address; // Not enough characters to format
    }

    const prefix = address.slice(0, 3);
    const suffix = address.slice(-3);
    return `${prefix}...${suffix}`;
  } else {
    return address;
  }
};

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // width: 40,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  leftChevron: {
    borderRadius: "50px",
    height: "250px",
    width: "25px",
    cursor: "pointer",
    outline: 0,
    border: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: " matrix(-1, 0, 0, 1, 0, 0)",
    background: "rgba(255, 255, 255, 0.10) !important",
    "& .MuiSvgIcon-root": {
      color: "rgba(255, 255, 255, 0.32)",
    },
    "&:hover": {
      // border: `3px solid ${theme.palette.primary.main}`,
    },
  },
  arrowRight: {
    transform: "rotate(180deg)",
  },
}));
