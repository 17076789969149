import { useState } from "react";
import { InputAdornment, InputBase, MenuItem, Select } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const SortDropdown = ({ data, setSortedData }) => {
  const [selectedOption, setSelectedOption] = useState("recentlyAdded");

  const handleSortChange = (e) => {
    const sortBy = e.target.value;
    setSelectedOption(sortBy);

    let sortedResults = [...data];

    switch (sortBy) {
      case "recentlyAdded":
        break;
      case "priceLowest":
        sortedResults.sort((a, b) => a.price - b.price);
        break;
      case "priceHighest":
        sortedResults.sort((a, b) => b.price - a.price);
        break;
      case "dateOldest":
        sortedResults.sort((a, b) => new Date(a.date) - new Date(b.date));
        break;
      case "dateNewest":
        sortedResults.sort((a, b) => new Date(b.date) - new Date(a.date));
        break;
      default:
        break;
    }
    setSortedData(sortedResults);
  };

  return (
    <Select
      id="dropdown-menu"
      onChange={handleSortChange}
      value={selectedOption}
      input={
        <InputBase
          startAdornment={<InputAdornment position="start">" "</InputAdornment>}
        />
      }
      sx={{
        p: 0.5,
        background: "transparent",
        color: "#fff",
        border: "1px solid #fff",
        borderRadius: "5px",
        ".MuiSelect-icon": { color: "#fff" },
      }}
      IconComponent={KeyboardArrowDownIcon}
    >
      <MenuItem value="recentlyAdded" disabled>
        Recently Added
      </MenuItem>
      <MenuItem value="priceLowest">Price: Lowest</MenuItem>
      <MenuItem value="priceHighest">Price: Highest</MenuItem>
      <MenuItem value="dateOldest">Date: Oldest</MenuItem>
      <MenuItem value="dateNewest">Date: Newest</MenuItem>
    </Select>
  );
};

export default SortDropdown;
