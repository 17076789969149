import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@mui/styles";
import { Box, CardActionArea } from "@mui/material";

const CardTrending = ({ price, nftName, userName, imageUrl, imageAlt }) => {
  const classes = useStyles();

  return (
    <Card className={`${classes.card} ${classes.cardHovered}`}>
      <CardActionArea>
        <CardMedia
          component="img"
          image={imageUrl}
          alt={imageAlt}
          className={classes.cardMedia}
        />
        <CardContent sx={{ padding: "10px !important" }}>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <Typography gutterBottom variant="h6">
                {nftName}
              </Typography>
              <Typography variant="caption">{userName}</Typography>
            </Stack>
            <Box display="flex">
              <Typography variant="body1" color="#C7D921">
                {price}&nbsp;ETH
              </Typography>
            </Box>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CardTrending;
const useStyles = makeStyles(() => ({
  card: {
    padding: "1rem !important",
    borderRadius: "1.5rem !important",
    backgroundColor: "#333431 !important",
    color: "white !important",
    overflow: "hidden !important",
    transition: "height 3s !important",
    display: "flex !important",
    width: "330px !important",
    justifyContent: "center !important",
    minHeight: "40vh !important",
  },

  cardMedia: {
    borderRadius: "2rem",
    aspectRatio: "3/3",
    objectFit: "contain",
  },
  cardIcon: {
    color: "white !important",
    padding: 0 + "!important",
    marginLeft: 5,
  },
  cardActions: {
    display: "flex !important",
    justifyContent: "space-between",
    padding: "10px 0 !important",
  },
  button: {
    width: "100% !important",
    padding: "0px !important",
    height: "35px !important",
    fontWeight: "600 !important",
  },
}));
