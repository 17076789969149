import { useFormik } from "formik";
import { useStyles } from "./style";
import ImageUpload from "../../../components/imageUpload";
import { Box, InputBase, Typography } from "@mui/material";
import UploadIcon from "../../../components/uploadIcon";
import AddIcon from "@mui/icons-material/Add";
import NFTDetails from "../../../components/nftDetails";
import PreviewImage from "../../../components/imagePreview";
import Button from "../../../components/button";
const CollectionMinting = () => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      image: "",
      title: "",
      description: "",
      amount: "",
    },
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.leftContainer}>
        <Box>
          <UploadIcon formik={formik} value={formik.values.image} />
        </Box>
        <Box className={classes.container}>
          <Box>
            <ImageUpload
              formik={formik}
              value={formik.values.image}
              helperText={
                (formik.touched.image && formik.errors.image) || `&nbsp;`
              }
              error={formik.touched.image && Boolean(formik.errors.image)}
            />
          </Box>
          <Box>
            <Typography className={classes.title}>
              Name <span>*</span>
            </Typography>
            <InputBase
              placeholder="eg. Name your NFT“"
              className={classes.nameInput}
              name="title"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Box>
          <Box>
            <Typography className={classes.title}>
              Description<span>*</span>
            </Typography>
            <Typography className={classes.description}>
              The description will be included on the item’s detail page
              underneath it’s image.
            </Typography>
            <textarea
              placeholder="eg. “Description your NFT”"
              className={classes.textArea}
              name="description"
              type="text"
              rows={7}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Box>
          <Box>
            <Typography className={classes.title}>
              No of NFTs <span>*</span>
            </Typography>
            <InputBase
              placeholder="eg. Number of NFT's“"
              className={classes.nameInput}
              name="amount"
              type="number"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Box>
          <Box className={classes.propertiesContainer}>
            <Box>
              <Typography> Properties</Typography>
              <Typography className={classes.description}>
                Textual traits that show up as rectangles
              </Typography>
            </Box>
            <Box pr={2}>
              <AddIcon className={classes.icon} />
            </Box>
          </Box>
          <Box>
            <Button
              variant="contained"
              onClick={formik.handleSubmit}
              className={classes.button}
            >
              Create
            </Button>
          </Box>
          <Box>
            <NFTDetails />
          </Box>
        </Box>
      </Box>
      <Box className={classes.rightContainer}>
        <PreviewImage
          formik={formik}
          value={formik.values.image}
          name={formik.values.title}
          description={formik.values.description}
        />
      </Box>
    </Box>
  );
};
export default CollectionMinting;
