import { Box, Typography } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useStyles } from "./style";
import BrowseFilters from "../../components/browseFilters";
import SingleCard from "../../components/card/singleCard";
import { data } from "./data";
import { useState } from "react";

const Browse = () => {
  const [sortedData, setSortedData] = useState(data);
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.filtersContainer}>
        {" "}
        <Box>
          <Typography color={"#fff"} variant="h3">
            All Networks
          </Typography>
        </Box>
        <Box>
          {" "}
          <Box className={classes.container}>
            <BrowseFilters setSortedData={setSortedData} data={data} />
          </Box>
          <Box className={classes.mobileFilter}>
            <FilterAltIcon
              sx={{
                color: "#E3FF00",
                background: " #484848",
                borderRadius: "5px",
                fontSize: "40px",
              }}
            />
          </Box>
        </Box>
      </Box>

      <Box pt={3} className={classes.cardContainer}>
        {sortedData &&
          sortedData.map((items) => {
            return <SingleCard {...items} />;
          })}
      </Box>
    </Box>
  );
};
export default Browse;
