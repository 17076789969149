import { Box, Typography } from "@mui/material";
import Carousel from "react-elastic-carousel";
import { useStyles } from "./style";
import BuyCardDetails from "../../components/cardDetails";
import AlphaMiniCard from "../../components/buyCards/alphaMiniCard";
import { data, dataTwo } from "./data";
import AlphaCard from "../../components/buyCards/alphaCard";
import { CarouselArrow } from "../../utils";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getAllArts,
  getArtHistory,
  getSingleArts,
} from "../../redux/arts/arts.action";
import Notifications from "../../components/notification";

const ArtDetail = () => {
  const [setFixedPrice] = useState();
  const { tokenId, amountId, chainId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    let payload = {
      tokenId,
      amountId,
      chainId,
    };
    dispatch(getAllArts());
    dispatch(getSingleArts(payload));
    dispatch(getArtHistory(payload));
  }, [dispatch, tokenId, amountId, chainId]);

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 },
  ];
  const classes = useStyles();
  return (
    <>
      <Notifications />
      <Box className={classes.mainContainer}>
        <Box>
          <BuyCardDetails setFixedPrice={setFixedPrice} />
        </Box>
        <Box className={classes.miniCardContainer}>
          {data &&
            data.map((items) => {
              return <AlphaMiniCard {...items} />;
            })}
        </Box>
        <Box pt={5}>
          <Box textAlign="center" py={2}>
            {" "}
            <Typography className={classes.alphaCardHeading}>
              More <span>From</span> The <span>Alpha Pack</span>
            </Typography>
          </Box>
          <Box>
            <Carousel
              autoPlaySpeed={5000}
              enableAutoPlay
              disableArrowsOnEnd={false}
              breakPoints={breakPoints}
              infinite={true}
              pagination={false}
              renderArrow={(arrowProps) => <CarouselArrow {...arrowProps} />}
              style={{
                padding: "0 !important",
              }}
            >
              {dataTwo &&
                dataTwo.map((items) => {
                  return <AlphaCard {...items} />;
                })}
            </Carousel>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ArtDetail;
