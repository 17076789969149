import { nftHunters } from "../../http/config";

export const checkEmailApi = async (payload) => {
  try {
    const res = await nftHunters.get(`checkEmail/${payload}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const checkUsernameApi = async (payload) => {
  try {
    const res = await nftHunters.get(`checkUsername/${payload}`);
    return res;
  } catch (error) {
    throw error;
  }
};

export const uploadProfileApi = async (payload) => {
  try {
    const res = await nftHunters.patch("uploadProfilePicture", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
export const uploadCoverApi = async (payload) => {
  try {
    const res = await nftHunters.patch("uploadCoverPicture", payload);
    return res;
  } catch (error) {
    throw error;
  }
};

export const updateUserInfoApi = async (payload) => {
  try {
    const res = await nftHunters.put("updateInfo", payload);
    return res;
  } catch (error) {
    throw error;
  }
};

export const inWalletApi = async (payload) => {
  try {
    const res = await nftHunters.get(`inWallet/${payload}`);
    return res;
  } catch (error) {
    throw error;
  }
};
