import { Box, Typography } from "@mui/material";
import Button from "../../components/button";
import SingleMintImage from "../../assets/images/singleImage.png";
import MultipleMintImage from "../../assets/images/collectionImage.png";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
const Create = () => {
  const classes = useStyles();
  return (
    <>
      <Box py={10} className={classes.container}>
        <Box className={classes.headingContainer}>
          <Box>
            <Typography className={classes.heading}>
              Create <span>NFT</span>
            </Typography>
          </Box>
          <Box>
            <Typography variant="body1" className={classes.description}>
              Select 'Single' if you desire a unique, one-of-a-kind collection,
              or 'Multiple' if you prefer <br /> to offer a collection for sale
              multiple times, allowing for broader accessibility
            </Typography>
          </Box>
        </Box>
        <Box className={classes.categoryContainer} py={5}>
          <Box className={classes.categories}>
            <img src={SingleMintImage} width="80%" alt="" />
            <Link to="/singleMinting">
              <Button variant="secondaryOutlined">Create Single</Button>
            </Link>
          </Box>
          <Box className={classes.categories}>
            <img src={MultipleMintImage} width="80%" alt="" />
            <Link to="/collectionMinting">
              <Button variant="contained">Create Multiple</Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Create;
const useStyles = makeStyles((theme) => ({
  container: {
    background: "#0F0F0F",
  },
  headingContainer: {
    textAlign: "center",
  },
  heading: {
    color: "#FFFFFF",
    fontSize: "63px !important",
    fontWeight: 700 + "!important",
    fontFamily: "Manrope",
    "& span": {
      color: "#E3FF00",
    },
  },
  description: {
    color: "#9B9B9B",
    fontSize: "16px !important",
    fontWeight: 500 + "!important",
    [theme.breakpoints.down("md")]: {
      padding: "0px 10px",
    },
  },
  categoryContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  categories: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
}));
