import { createSlice } from "@reduxjs/toolkit";
import { getAllArts, getArtHistory, getSingleArts } from "./arts.action";

const initialState = {
  // All arts
  isAllArtsLoading: false,
  isAllArtsSuccess: false,
  AllArts: [],
  // Single Arts
  isSingleArtsLoading: false,
  isSingleArtsSuccess: false,
  singleArts: {},
  //art History
  isArtHistorySuccess: false,
  isArtHistoryLoading: false,
  isArtHistoryFalse: false,
  artHistory: [],
};

const artsSlice = createSlice({
  name: "arts",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllArts.pending, (state) => {
      state.isAllArtsLoading = true;
    });
    builder.addCase(getAllArts.fulfilled, (state, action) => {
      state.isAllArtsLoading = false;
      state.isAllArtsSuccess = true;
      state.AllArts = action.payload;
    });
    builder.addCase(getAllArts.rejected, (state) => {
      state.isAllArtsLoading = false;
      state.isAllArtsSuccess = false;
    });
    // Single Arts
    builder.addCase(getSingleArts.pending, (state) => {
      state.isSingleArtsLoading = true;
    });
    builder.addCase(getSingleArts.fulfilled, (state, action) => {
      state.isSingleArtsLoading = false;
      state.isSingleArtsSuccess = true;
      state.singleArts = action.payload;
    });
    builder.addCase(getSingleArts.rejected, (state) => {
      state.isSingleArtsLoading = false;
      state.isSingleArtsSuccess = false;
    });
    //  Arts History
    builder.addCase(getArtHistory.pending, (state) => {
      state.isArtHistoryLoading = true;
    });
    builder.addCase(getArtHistory.fulfilled, (state, action) => {
      state.isArtHistoryLoading = false;
      state.isArtHistorySuccess = true;
      state.artHistory = action.payload;
    });
    builder.addCase(getArtHistory.rejected, (state) => {
      state.isArtHistoryLoading = false;
      state.isArtHistorySuccess = false;
      state.isArtHistoryFalse = true;
    });
  },
});

export default artsSlice.reducer;
