import { Box } from "@mui/material";

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: {
              lg: "space-between",
              md: "space-between",
              sm: "center",
              xs: "center",
            },
            gap: "20px",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
};
export default TabPanel;
