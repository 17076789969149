import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "rgba(255, 255, 255, 0.15)",
  borderRadius: "10px",
  boxShadow: 24,
  padding: "15px 25px",
};

const WalletModal = ({ open, handleClose }) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.8)", // Here, 0.5 is the opacity value (50% opacity)
          opacity: 100,
        }}
      >
        <Box sx={style}>{/* <Wallet handleClose={handleClose} /> */}</Box>
      </Modal>
    </div>
  );
};
export default WalletModal;
