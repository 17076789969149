import { Box, Typography } from "@mui/material";
import EditprofileTabs from "../../components/editProfile/editProfileTabs";
import { useStyles } from "./style";
import Notifications from "../../components/notification";

const EditProfile = () => {
  const classes = useStyles();

  return (
    <>
      <Notifications />
      <Box className={classes.container}>
        <Box
          sx={{
            padding: {
              lg: "20px 30px",
              md: "20px 30px",
              sm: "10px 10px",
              xs: "10px 10px",
            },
          }}
        >
          <Typography className={classes.heading}>Profile Setting</Typography>
        </Box>
        <Box width={"100%"}>
          <EditprofileTabs />
        </Box>
      </Box>
    </>
  );
};
export default EditProfile;
