import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gap: 10,
    gridTemplateColumns: "0.9fr 1fr",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
    },
  },
  detailContainer: {
    background: "rgba(255, 255, 255, 0.10)",
    width: "100%",
    borderRadius: "5px",
    padding: "10px 20px",
    height: "100%",
  },
  heading: {
    color: "#C7D921",
    fontSize: "15px",
    fontWeight: 700 + "!important",
    textDecoration: "underline",
  },
  secondHeading: {
    color: "#fff",
    fontSize: "27px !important",
    fontWeight: 700 + "!important",
    textDecoration: "underline",
    padding: "10px 0",
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
  },
  iconContainer: {
    background: "#C7D921",
    padding: "5px 8px",
    borderRadius: "10px",
    fontSize: "10px",
    cursor: "pointer",
    "&:hover": {
      background: "#C7D000",
    },
  },
  ownerDetails: {
    display: "flex",
    gap: 7,
    alignItems: "start",
  },
  ownerImage: {
    borderRadius: "50px",
    // marginBottom: 2,
  },
  ownerName: {
    color: "#C0D709",
    fontSize: "12px !important",
    fontWeight: 400 + "!important",
    fontFamily: "Manrope",
  },
  rankContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: " #1B1B1E",
    borderRadius: "3px",
    width: "30%",
    padding: "7px 2px",
    gap: "5px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  rank: {
    color: "#C0D709",
    fontSize: "10px !important",
    "& span": {
      color: "#fff",
      fontSize: "10px !important",
    },
  },
  price: {
    fontSize: "20px !important",
    fontWeight: 800 + "!important",
    color: "#fff",
    "& span": {
      color: "#C0D709",
      fontSize: "14px",
      fontWeight: 400 + "!important",
    },
  },
  priceHeading: {
    fontSize: "16px !important",
    fontWeight: 500 + "!important",
    color: "#fff",
  },
  dash: {
    fontSize: "24px !important",
    fontWeight: 800 + "!important",
    color: "#fff",
  },
  buttonContainer: {
    display: "flex",
    alignContent: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  artImage: {
    width: "100%",
    height: "600px",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));
