import { createSlice } from "@reduxjs/toolkit";
import { mint } from "./mint.action";

const initialState = {
  isMintLoading: false,
  isMintSuccess: false,
  isMintFailed: false,
  mintSuccess: {},
  mintFailed: {},
};

const mintSlicer = createSlice({
  name: "mint",
  initialState,
  reducers: {
    resetStateMint: (state) => {
      state.isMintLoading = false;
      state.isMintSuccess = false;
      state.isMintFailed = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(mint.pending, (state) => {
      state.isMintLoading = true;
      state.isMintSuccess = false;
      state.isMintFailed = false;
    });
    builder.addCase(mint.fulfilled, (state, action) => {
      state.isMintLoading = false;
      state.isMintSuccess = true;
      state.isMintFailed = false;
      state.mintSuccess = action.payload.message;
    });
    builder.addCase(mint.rejected, (state, action) => {
      state.isMintLoading = false;
      state.isMintSuccess = false;
      state.isMintFailed = true;
      state.mintFailed = action.payload;
    });
    ////////////////////////////////////////////////////////////////
  },
});

export default mintSlicer.reducer;
export const { resetStateMint } = mintSlicer.actions;
