export const palette = {
  primary: {
    main: "#FFFFFF",
  },
  secondary: {
    main: "#C0D709",
  },
  background: {
    paper: "#FFFFFF26",
    default: "",
  },
  text: {
    primary: "#ffffff",
  },
  divider: "#63B5E4",
};
