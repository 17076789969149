import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#0F0F0F !important",
    color: "#FFFFFF !important",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    padding: "60px 30px",
    gap: "40px",

    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
      padding: "0px 0px 45px 0",
    },
  },
  leftContainer: {
    display: "flex !important",
    flexDirection: "column",
    gap: 20,
    flex: 1,
    [theme.breakpoints.down("md")]: {
      // width: "100vw !important",
      alignItems: "center",
    },
  },
  container: {
    display: "flex !important",
    flexDirection: "column",
    gap: 20,
    [theme.breakpoints.down("md")]: {
      width: "90vw !important",
    },
  },
  nameInput: {
    background: "#1E1C1F",
    color: "#7C7C7C !important",
    fontSize: "10px !important",
    fontWeight: 400 + "!important",
    padding: 15,
    borderRadius: "10px !important",
    width: "100% !important",
  },
  title: {
    fontSize: "24px",
    fontWeight: 400 + "!important",
    "& span": {
      color: "#AF0A0A",
    },
  },
  description: {
    color: "#848484",
    fontSize: "10px !important",
    padding: "0px 0 5px 0px !important",
  },
  textArea: {
    background: "#1E1C1F",
    color: "#7C7C7C !important",
    fontSize: "10px !important",
    fontWeight: 400 + "!important",
    padding: "10px 15px",
    borderRadius: "10px !important",
    width: "100% !important",
    border: "none !important",
    "&::placeholder": {},
  },
  propertiesContainer: {
    background: "#1E1C1F",
    borderRadius: "10px !important",
    padding: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  icon: {
    background: "#E3FF00",
    color: "#000",
    display: "flex",
    alignItems: "center",
  },
  rightContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    [theme.breakpoints.down("md")]: {
      order: -1,
      alignItems: "start",
      paddingTop: "20px",
      height: "100%",
    },
  },
  button: {
    width: "150px",
  },
}));
