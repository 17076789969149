import { Box, Grid, Typography } from "@mui/material";
import CustomAccordian from "../accordion";
import HeadzImg from "../../assets/images/redEyes.png";
import { makeStyles } from "@mui/styles";

const FAQ = () => {
  const classes = useStyles();
  return (
    <Box py={6}>
      <Box textAlign="center" pb={10}>
        <Typography
          variant="h2"
          color="#fff"
          fontSize="35.708px"
          fontWeight="700"
        >
          Frequently <span style={{ color: "#E3FF00" }}> Asked Question</span>
        </Typography>
      </Box>
      <Box padding="5px 15px">
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box display="flex" justifyContent="center">
              <img src={HeadzImg} alt="Monkey" className={classes.image} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={6}>
            <Box>
              <CustomAccordian />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default FAQ;
const useStyles = makeStyles((theme) => ({
  image: {
    width: "35vw",
    minWidth: "35vw",
    height: "70vh",
    minHeight: "70vh",
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));
