import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from "@mui/material";

import React from "react";
import { makeStyles } from "@mui/styles";
import Button from "../../button/index";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { profileBaseURL } from "../../../http/config";
import icon from "../../../assets/icons/Group 12.png";
import { ShortString } from "../../../utils";

const NFTCard = ({
  title,
  ownerUsername,
  amountId,
  tokenId,
  chainId,
  fixedPrice,
  image,
}) => {
  const {
    user: { img },
  } = useSelector((state) => state.auth);
  const classes = useStyles();
  return (
    <>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          avatar={
            <Avatar aria-label="recipe">
              <CardMedia
                component="img"
                image={profileBaseURL + img}
                alt=""
                sx={{ objectFit: "cover" }}
              />
            </Avatar>
          }
          title={<ShortString address={ownerUsername} />}
        />
        <Link to={`/${tokenId}/${amountId}/${chainId}`}>
          <CardMedia
            component="img"
            image={image}
            sx={{ objectFit: "cover", minHeight: "300px", maxHeight: "300px" }}
          />
        </Link>
        <CardContent className={classes.content}>
          <Box>
            <Typography className={classes.subTitle}>{title}</Typography>
            <Typography className={classes.editionPart}>
              Edition to length
            </Typography>
          </Box>
          <Box className={classes.contentContainer}>
            <Box className={classes.pricePara}>
              <CardMedia>
                <img src={icon} alt="" />
              </CardMedia>
              <Box>
                <Typography className={classes.priceTypo}>Price</Typography>
                <Typography
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  {fixedPrice} <span>ETH</span>
                </Typography>
              </Box>
            </Box>
            <Button className={classes.cardBtn} variant="secondaryContained">
              Buy now
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default NFTCard;
const useStyles = makeStyles({
  card: {
    background:
      "linear-gradient(180deg, rgba(225, 255, 0, 0.3) 0%, rgba(55, 61, 10, 0) 100%, #B4CA0A 90%) !important",
    color: "#fff !important",
    maxWidth: 320,
    minWidth: 320,
    padding: "0px 20px 0px 20px",
    fontFamily: "Manrope !important",
    margin: "30px 0px",
  },
  cardHeader: {
    padding: "15px 0px !important",
  },
  content: {
    padding: "20px 0 10px 0 !important",
  },
  pricePara: {
    display: "flex",
    gap: "15px",
  },
  cardBtn: {
    width: "140px",
  },
  contentContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  priceTypo: {
    fontSize: "0.7rem !important",
    color: "#FFFFFF99",
  },
  editionPart: {
    fontSize: "11px !important",
    color: "#FFFFFF99",
    fontFamily: "Manrope !important",
  },
});
