import { Box, Typography } from "@mui/material";
import blueTick from "../../assets/icons/blueTick.svg";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";

const MyCarts = ({ image, title, company, pecentage, price }) => {
  const classes = useStyles();
  const [hovered, setHovered] = useState(false);

  return (
    <Box
      className={classes.container}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box>
        {" "}
        <img src={image} className={classes.image} alt="ownerImage" />
      </Box>
      <Box>
        <Typography className={classes.title}>{title}</Typography>
        <Typography className={classes.verificationContainer}>
          {company}
          <img src={blueTick} alt="" />
        </Typography>
        <Typography className={classes.earnings}>
          Creator earnings: {pecentage}
        </Typography>
      </Box>
      <Box className={classes.actionContainer}>
        {hovered ? (
          <DeleteIcon sx={{ color: "#D3D3D3" }} />
        ) : (
          <Typography className={classes.price}> {price} ETH</Typography>
        )}
      </Box>
    </Box>
  );
};

export default MyCarts;

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 10px",
    borderRadius: "5px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.04)",
    },
  },
  verificationContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px !important",
    fontWeight: 400 + "!important",
    color: "#fff",
    gap: 3,
  },
  title: {
    fontSize: "14px !important",
    fontWeight: 600 + "!important",
    color: "#fff",
  },
  earnings: {
    fontSize: "12px !important",
    fontWeight: 400 + "!important",
    color: "#B3B3B3",
  },
  image: {
    borderRadius: "10px",
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    width: "80px",
  },
  price: {
    fontSize: "14px !important",
    fontWeight: 400 + "!important",
    color: "#fff",
  },
}));
