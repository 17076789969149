import { Outlet } from "react-router-dom";

import Header from "../header";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Footer from "../footer";

const Layout = () => {
  const classes = useStyles();
  return (
    <Box>
      {/* <ScrollUp /> */}

      <Box>
        <Box className={classes.container}>
          <Header />
        </Box>
        <Box
          sx={{
            minHeight: "80.25vh",
          }}
        >
          <Outlet />
        </Box>
        <Footer />
        {/* <LoginError open={!isAuthenticated} /> */}
      </Box>
    </Box>
  );
};

export default Layout;
const useStyles = makeStyles((theme) => ({
  container: {
    background: "linear-gradient(60deg, #000 98.63%, #E3FF00 0.25%)",
  },
}));
