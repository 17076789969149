import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import listIcon from "../../assets/icons/listIcon.png";
import walletIcon from "../../assets/icons/wallet.png";
import nftIcon from "../../assets/icons/nftIcon.png";
import collectionIcon from "../../assets/icons/collectionIcon.png";
const NFTDetails = () => {
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.container}>
        <Typography className={classes.heading}>
          Create and sell your NFT’s
        </Typography>
      </Box>
      <Box className={classes.detailsContainer}>
        <Box>
          <img src={walletIcon} alt="wallet" />
          <Typography className={classes.title}>
            1. Set up your wallet
          </Typography>
          <Typography className={classes.description}>
            Once you have set up a wallet of your choice , connect it to opensea
            by clicking the NFT markeplace in The top right corner
          </Typography>
        </Box>
        <Box>
          <img src={collectionIcon} alt="wallet" />
          <Typography className={classes.title}>
            2. Create your collection
          </Typography>
          <Typography className={classes.description}>
            Click create and set up your collection. Add social links,
            description, profile and banner images and customize your NFTs with
            properties, stats.
          </Typography>
        </Box>
        <Box>
          <img src={nftIcon} alt="wallet" />
          <Typography className={classes.title}>3. Add your NFTs</Typography>
          <Typography className={classes.description}>
            Once you have set up a wallet of your choice , connect it to opensea
            by clicking the NFT markeplace in The top right corner
          </Typography>
        </Box>
        <Box>
          <img src={listIcon} alt="wallet" />
          <Typography className={classes.title}>
            4. List them for sale
          </Typography>
          <Typography className={classes.description}>
            Choose between auctions, fixed price listings and declining price
            listings. You choose how you want to sell your NFTs!
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default NFTDetails;
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background: "#1B1B1E",
    borderRadius: "0 0px 10px 10px",
    padding: 10,
  },
  detailsContainer: {
    display: "flex",
    textAlign: "center",
    gap: 5,
  },
  container: {
    textAlign: "center",
    padding: "10px 0",
  },
  heading: {
    fontWeight: 800 + "!important",
    fontSize: "27px !important",
    color: "#C0D709",
  },
  title: {
    fontSize: "13px !important",
    fontWeight: 800 + "!important",
  },
  description: {
    fontSize: "8px !important",
    fontWeight: 500 + "!important",
  },
}));
