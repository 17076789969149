import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useStyles } from "./style";
import CachedIcon from "@mui/icons-material/Cached";
import diamondImage from "../../assets/icons/diamond.svg";
import adidasImage from "../../assets/icons/adidas.png";
import cartIcon from "../../assets/icons/cart.png";
import { directTransfer } from "../../redux/transfer/transfer.action";
import Links, { ArtBlockchainDetails } from "../links";
import { useDispatch, useSelector } from "react-redux";
import { useAddress, useContract } from "@thirdweb-dev/react";

import {
  marketplaceContractAddress,
  marketplaceContractType,
} from "../../hooks/thirdwebHooks/contractAddresses";
import { profileBaseURL } from "../../http/config";
import { ShortString } from "../../utils";
import Notifications from "../notification";
const BuyCardDetails = ({ setFixedPrice }) => {
  const { singleArts } = useSelector((state) => state.arts);
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box>
        <img src={singleArts?.image} className={classes.artImage} alt="" />
      </Box>
      <Box width="100%">
        <CardDetails {...singleArts} setFixedPrice={setFixedPrice} />
      </Box>
    </Box>
  );
};

export default BuyCardDetails;

///////////////////////////////////////////////////////////////////////////
const CardDetails = ({
  title,
  description,
  ownerWallet,
  tokenId,
  chainId,
  fixedPrice,
  onSale,
  orderId,
  ownerImg,
  setFixedPrice,
  fixPriceSubmit,
  image,
  creatorWallet,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const wallet = useAddress();
  const { contract } = useContract(
    marketplaceContractAddress,
    marketplaceContractType
  );
  const handleBuy = async () => {
    try {
      setFixedPrice(true);
      const txResult = await contract?.directListings.buyFromListing(
        orderId,
        1
      );

      let payload = {
        tokenId: tokenId,
        orderId: orderId,
        transferHash: txResult.receipt.transactionHash,
        transferFrom: ownerWallet,
        transferTo: wallet,
        amount: fixedPrice,
        amountId: 1,
        chainId: chainId,
      };
      dispatch(directTransfer(payload));
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setFixedPrice(false);
    }
  };

  return (
    <Box className={classes.detailContainer}>
      <Notifications />
      <Box className={classes.topContainer}>
        <Box>
          <Typography className={classes.heading}>The Alpha Pack</Typography>
        </Box>
        <Box className={classes.iconContainer}>
          <CachedIcon />
        </Box>
      </Box>
      <Box>
        {" "}
        <Typography className={classes.secondHeading}>{title}</Typography>
      </Box>
      <Box pb={1.5} className={classes.ownerDetails}>
        <Typography className={classes.ownerName}>Owner:</Typography>
        <Box display={"flex"} alignItems={"center"} gap={"5px"}>
          <img
            src={profileBaseURL + ownerImg}
            className={classes.ownerImage}
            width="20px"
            alt="profile"
          />{" "}
          <Typography className={classes.ownerName}>
            <ShortString address={ownerWallet} />
          </Typography>
        </Box>
      </Box>
      <Box className={classes.rankContainer}>
        <img src={diamondImage} alt="" />
        <Typography className={classes.rank}>
          Rank <span>484</span>
        </Typography>
      </Box>
      <Box display="flex" gap="35px" pt={2} pb={1}>
        <Box>
          <Typography className={classes.priceHeading}>Price</Typography>
          <Box display="flex" gap="10px" alignItems="center">
            <img src={adidasImage} width="20px" alt="" />{" "}
            <Typography className={classes.price}>
              {fixedPrice} <span>$87.5</span>
            </Typography>{" "}
          </Box>
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography className={classes.priceHeading}>Top Offer</Typography>
          <Typography className={classes.dash}>-</Typography>
        </Box>
      </Box>
      {onSale === 1 && (
        <>
          {ownerWallet === creatorWallet ? (
            <Typography className={classes.price}>
              You Owned this NFT
            </Typography>
          ) : (
            <>
              <Box className={classes.buttonContainer}>
                <Box>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      color: "#000",
                      background: "#C7D921",
                      padding: "10px 25px",
                      borderRadius: "5px 0px 0px 8px ",
                      fontSize: "14px ",
                      width: "150px",
                      "&:hover": {
                        background: "#C7D000",
                      },
                    }}
                    onClick={() => handleBuy()}
                  >
                    {fixPriceSubmit ? <CircularProgress /> : "Buy Now"}
                  </Button>
                </Box>
                <Box sx={{ cursor: "pointer" }}>
                  <img
                    src={cartIcon}
                    style={{
                      background: "#424242",
                      padding: "5px",
                      width: "50px",
                      height: "44.5px",
                      borderRadius: "0px 5px 5px 0px",
                    }}
                    alt=""
                  />
                </Box>
              </Box>
            </>
          )}
        </>
      )}

      <Box pt={2}>
        <CardEntries description={description} title={title} image={image} />
      </Box>
    </Box>
  );
};
/////////////////////////////////////////////////////////////////////
const CardEntries = ({ description, title, image }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <img
          src={image}
          style={{ borderRadius: "5px", width: "30px", objectFit: "fill" }}
          alt="dog"
        />
        <Typography sx={{ color: "#fff", fontSize: "16px", fontWeight: 500 }}>
          {title}
        </Typography>
      </Box>
      <Box maxWidth="500px">
        <Typography sx={{ fontSize: "12px", fontWeight: 400, color: "white" }}>
          {description}
        </Typography>
      </Box>
      <Box>
        <Links />
      </Box>
      <Box>
        <ArtBlockchainDetails />
      </Box>
    </Box>
  );
};
/////////////////////////////////////////////////////////
