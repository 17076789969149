import { Box, Typography } from "@mui/material";
import foxImage from "../../../assets/icons/foxImage.png";
import adidasIcon from "../../../assets/icons/adidas.png";
import alphaCardIcon from "../../../assets/icons/alphaCardIcon.png";
const AlphaCard = () => {
  return (
    <Box sx={{ background: "#1B1B1E", height: "100%" }}>
      <Box>
        <img src={foxImage} alt="fox" width={"280px"} />
      </Box>
      <Box p={1}>
        <Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: 600,
              fontStyle: "normal",
              color: "#fff",
            }}
          >
            The Alpha Pack #4607
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          pt={0.5}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: 700,
                fontStyle: "normal",
                color: "#fff",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <img src={adidasIcon} alt="adidas" /> 2.5
            </Typography>
          </Box>
          <Box>
            <img src={alphaCardIcon} alt="" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default AlphaCard;
