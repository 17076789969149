import { nftHunters } from "../../http/config";

export const directTransferApi = async (payload) => {
  try {
    const res = await nftHunters.post("directTransfer", payload);
    return res;
  } catch (error) {
    throw error;
  }
};
