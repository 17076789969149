import * as React from "react";
import { CustomTabs, Tabs } from "../tabs/customTab";
import { Box } from "@mui/material";
import TabPanel from "../tabs/tabPanel";
import ProfileFilters from "../profileFilters";
import { data } from "./data";
import SingleCard from "../card/singleCard";

const ProfileWrapper = ({ children }) => {
  return (
    <Box
      sx={{
        paddingLeft: { lg: 2.5, md: 1, sm: 1, xs: 1 },
        paddingRight: { lg: 2.5, md: 1, sm: 1, xs: 1 },
      }}
    >
      {children}
    </Box>
  );
};

const ProfileTabs = ({ createdArts, collectedArts, createdCollections }) => {
  const [value, setValue] = React.useState(0);
  // const [filter, setFilter] = React.useState(createdArts);
  const tabs = ["Created Collections", "Created Arts", "Collected Arts"];
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  // const handleSearchChange = (e) => {
  //   const query = e.target.value.toLowerCase();

  //   const filteredResults = data.filter(
  //     (item) => item.title && item.title.toLowerCase().includes(query)
  //   );

  //   setFilter(filteredResults);
  // };

  return (
    <Box>
      <ProfileWrapper>
        <Tabs value={value} onChange={handleChange}>
          {tabs.map((items) => (
            <CustomTabs label={items} />
          ))}
        </Tabs>
        <Box>
          <ProfileFilters
            // handleChange={handleSearchChange}
            data={data}
            // setSortedData={setFilter}
            // setFilter={setFilter}
          />
        </Box>
        <Box>
          <TabPanel value={value} index={0}>
            {createdCollections &&
              createdCollections.map((items) => {
                return <SingleCard {...items} />;
              })}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {createdArts &&
              createdArts.map((items) => {
                return <SingleCard {...items} />;
              })}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {collectedArts &&
              collectedArts.map((items) => {
                return <SingleCard {...items} />;
              })}
          </TabPanel>
        </Box>
      </ProfileWrapper>
    </Box>
  );
};
export default ProfileTabs;
