import AddIcon from "@mui/icons-material/Add";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
} from "@mui/material";
import QuestionIcon from "../../assets/icons/question.svg";

const CustomAccordian = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Accordion
        sx={{
          background: "#000000",
          color: "#fff",
          border: "1px solid #1b1b1b",
          display: "grid",
          justifyItems: "space-between",
        }}
        defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<AddIcon sx={{ color: "white", fontSize: "30px" }} />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{
            padding: "0px 10px 0px 10px",
            "& .Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <img src={QuestionIcon} width="20px" alt="" />
          &nbsp;&nbsp;&nbsp; <p>What is a Multi-Chain NFT Marketplace?</p>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "#1b1b1b" }}>
          A Multi-Chain NFT Marketplace is a digital platform where users can
          create, buy, sell, and trade non-fungible tokens (NFTs) across
          multiple blockchain networks. Unlike traditional marketplaces that
          operate on a single blockchain, a multi-chain marketplace offers
          broader accessibility, increased flexibility, and potentially lower
          transaction fees by supporting various blockchains. This enables users
          to interact with a diverse range of NFTs from different ecosystems
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{
          background: "#000000",
          color: "#fff",
          border: "1px solid #1b1b1b",
          display: "grid",
          justifyItems: "space-between",
        }}
        // defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<AddIcon sx={{ color: "white", fontSize: "30px" }} />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{
            padding: "0px 10px 0px 10px",
            "& .Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <img src={QuestionIcon} width="20px" alt="" />
          &nbsp;&nbsp;&nbsp;{" "}
          <p>
            {" "}
            Why use a Multi-Chain NFT Marketplace instead of a single-chain
            platform?
          </p>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "#1b1b1b",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <h4>Multi-Chain NFT Marketplaces offer several advantages:</h4>
          <p>
            Broader Accessibility: Users can access NFTs from multiple
            blockchains in one place, expanding the variety of available digital
            assets.
          </p>
          <p>
            <span style={{ fontSize: "14px" }}>
              Lower Transaction Fees: By choosing a blockchain with lower
              transaction costs, users can save money on fees.
            </span>
          </p>
          <p>
            Increased Flexibility: Users have more options for buying, selling,
            and trading NFTs, as they are not restricted to the rules or
            limitations of a single blockchain.
          </p>
          <p>
            Enhanced Liquidity: A larger pool of assets and users from different
            blockchains can lead to better liquidity and potentially higher
            asset values.
          </p>
        </AccordionDetails>
      </Accordion>{" "}
      <Accordion
        sx={{
          background: "#000000",
          color: "#fff",
          border: "1px solid #1b1b1b",
          display: "grid",
          justifyItems: "space-between",
        }}
        // defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<AddIcon sx={{ color: "white", fontSize: "30px" }} />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{
            padding: "0px 10px 0px 10px",
            "& .Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <img src={QuestionIcon} width="20px" alt="" />
          &nbsp;&nbsp;&nbsp;{" "}
          <p>How do I start buying NFTs on a Multi-Chain NFT Marketplace?</p>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "#1b1b1b",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <span>
            {" "}
            <h4>To start buying NFTs, follow these steps:</h4>
            Create a Digital Wallet: Set up a digital wallet compatible with the
            blockchains supported by the marketplace.{" "}
          </span>
          <p>
            Fund Your Wallet: Add cryptocurrency to your wallet. Ensure you have
            the right currency for the blockchain you wish to transact on.
          </p>{" "}
          <p>
            Browse the Marketplace: Explore the marketplace for NFTs you're
            interested in. You can often search by category, artist, or
            blockchain.{" "}
          </p>
          <p>
            Make a Purchase: Once you've found an NFT you like, follow the
            marketplace's instructions to make a purchase. This may involve
            bidding in an auction or buying at a fixed price.
          </p>
        </AccordionDetails>
      </Accordion>{" "}
      <Accordion
        sx={{
          background: "#000000",
          color: "#fff",
          border: "1px solid #1b1b1b",
          display: "grid",
          justifyItems: "space-between",
        }}
        // defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<AddIcon sx={{ color: "white", fontSize: "30px" }} />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{
            padding: "0px 10px 0px 10px",
            "& .Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <img src={QuestionIcon} width="20px" alt="" />
          &nbsp;&nbsp;&nbsp;{" "}
          <p>
            {" "}
            Can I transfer NFTs bought on one blockchain to another within a
            Multi-Chain Marketplace?
          </p>
        </AccordionSummary>
        <AccordionDetails sx={{ backgroundColor: "#1b1b1b" }}>
          The ability to transfer NFTs between blockchains in a Multi-Chain
          Marketplace depends on the specific marketplace and the blockchains
          involved. Some marketplaces offer "bridging" services that allow for
          the transfer of assets between blockchains, but this is not
          universally available. It's important to check the marketplace's
          features and the compatibility of the NFTs with the target blockchain
          before attempting a transfer.
        </AccordionDetails>
      </Accordion>{" "}
      <Accordion
        sx={{
          background: "#000000",
          color: "#fff",
          border: "1px solid #1b1b1b",
          display: "grid",
          justifyItems: "space-between",
        }}
        // defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<AddIcon sx={{ color: "white", fontSize: "30px" }} />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{
            padding: "0px 10px 0px 10px",
            "& .Mui-expanded": {
              margin: 0,
            },
          }}
        >
          <img src={QuestionIcon} width="20px" alt="" />
          &nbsp;&nbsp;&nbsp;{" "}
          <p>Are transactions on a Multi-Chain NFT Marketplace safe?</p>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: "#1b1b1b",
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <h4>
            {" "}
            Transactions on a Multi-Chain NFT Marketplace are generally secure,
            as they leverage the underlying blockchains' security mechanisms.
            However, users should exercise caution by:
          </h4>{" "}
          <p>
            {" "}
            - Using a reputable marketplace with strong security measures in
            place.{" "}
          </p>
          <p>
            {" "}
            - Keeping their digital wallet and private keys secure. - Being
            cautious of phishing &nbsp;&nbsp;scams and only interacting with
            verified sellers.
          </p>
          <p>
            {" "}
            - By offering a platform that operates across multiple blockchains,
            Multi-Chain &nbsp;&nbsp;NFT Marketplaces are paving the way for a
            more interconnected and &nbsp;&nbsp;accessible digital asset
            ecosystem.
          </p>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
export default CustomAccordian;
