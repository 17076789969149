import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FileUploader } from "react-drag-drop-files";
const fileTypes = ["JPG", "PNG", "GIF", "JPEG", "SVG"];
const ImageUpload = ({ formik }) => {
  const handleChange = (file) => {
    formik.setFieldValue("image", file);
  };
  const classes = useStyles();
  return (
    <FileUploader handleChange={handleChange} name="file" types={fileTypes}>
      <Box className={classes.upload}>
        <Box className={classes.uploadContainer}>
          <Typography variant="body1" className={classes.title}>
            Choose file/drag file
          </Typography>{" "}
          <Typography className={classes.filetype}>
            JPG,JPEG,PNG,GIF,SVG,MP4,WEBPMP3,WAV,OGG,GLB,GLTF. Max size 100mb
          </Typography>
        </Box>
      </Box>
    </FileUploader>
  );
};
export default ImageUpload;
const useStyles = makeStyles((theme) => ({
  image: {
    color: "#E3FF00 !important",
  },
  uploadContainer: {
    background: "#1E1C1F",
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    flexDirection: "column",
    alignItems: "center",
    gap: 10,
    borderRadius: "10px",
  },
  title: {
    background: "#4D4D4D",
    padding: 10,
    borderRadius: "10px",
    fontSize: "16px !important",
    fontWeight: 600 + "!important",
  },
  filetype: {
    color: "#7C7C7C",
    fontSize: "12px !important",
    fontWeight: 400 + "!important",
  },
  helperText: {
    marginTop: 2,
    fontSize: "1rem",
  },
  error: {
    color: "red",
  },
}));
