import { Box } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { getSingleArts } from "../../redux/arts/arts.action";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
// import Notifications from "../../components/notification";
import ListingDetails from "../../components/lsitingDetail";
import Notifications from "../../components/notification";
const Listing = () => {
  const { isPutOnFixPriceSuccess } = useSelector(
    (state) => state.putOnFixedPrice
  );
  const { tokenId, amountId, chainId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    let payload = {
      tokenId,
      amountId,
      chainId,
    };
    dispatch(getSingleArts(payload));
  }, [dispatch, tokenId, amountId, chainId]);
  useEffect(() => {
    if (isPutOnFixPriceSuccess) {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    }
  }, [isPutOnFixPriceSuccess, navigate]);
  return (
    <>
      <Notifications />
      <Box
        sx={{
          background: "#0F0F0F !important",
          height: "85vh !important",
          display: "flex",
          alignItems: "center",
        }}
      >
        <ListingDetails />
      </Box>
    </>
  );
};

export default Listing;
