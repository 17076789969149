import { Box, Typography } from "@mui/material";
import userProfile from "../../assets/images/cardImg.png";
import { makeStyles } from "@mui/styles";

const PreviewImage = ({ value, name, description }) => {
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.userDetails}>
        <img className={classes.userImage} src={userProfile} alt="" />
        <Typography className={classes.userName}>Muzahir Hussain</Typography>
      </Box>
      <Box className={classes.previewContainer}>
        {!value ? (
          <>
            <Typography className={classes.innerText}>Preview Image</Typography>
            <span className={classes.span}>
              JPG,JPEG,PNG,GIF,SVG. Max size 100mb
            </span>
          </>
        ) : (
          <img
            src={value && URL.createObjectURL(value)}
            alt="Preview"
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        )}
      </Box>
      <Box>
        <Typography className={classes.userName}>
          {name === "" ? "Your NFT Name" : name}
        </Typography>
        <Typography className={classes.descriptionTitle}>
          {description === "" ? "NFT Description" : description}
        </Typography>
      </Box>
    </Box>
  );
};
export default PreviewImage;
const useStyles = makeStyles((theme) => ({
  mainContainer: {
    background:
      "linear-gradient(180deg, rgba(225, 255, 0, 0.73) 0%, #313606 102.11%, rgba(55, 61, 10, 0.00) 102.12%, #B4CA0A 102.12%, #E1FF00 102.12%)",
    borderRadius: "10px",
    border: " 2px solid #E3FF00",
    maxWidth: "350px",
    padding: "20px 20px",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  userDetails: {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  previewContainer: {
    background: "#0F0F0F",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "350px",
    flexDirection: "column",
    gap: "10px",
    textAlign: "center",
    minWidth: "300px",
  },
  userName: {
    color: "#FFFFFF",
    fontWeight: 700 + "!important",
    textTransform: "capitalize !important",
  },
  userImage: {
    borderRadius: "50%",
    width: "50px",
  },
  innerText: {
    color: "#7C7C7C",
  },
  span: {
    color: "#7C7C7C",
    fontSize: "10px",
  },
  descriptionTitle: {
    color: "rgba(255, 255, 255, 0.60)",
    fontSize: "14px !important",
    fontWeight: 400 + "!important",
  },
}));
