import createrImage from "../../assets/images/cartImage.png";
export const data = [
  {
    image: createrImage,
    title: "Highland Cow #179",
    company: "Good Morning Cafe",
    pecentage: " 10%",
    price: "0.003",
  },
  {
    image: createrImage,
    title: "Highland Cow #179",
    company: "Good Morning Cafe",
    pecentage: " 10%",
    price: "0.003",
  },
  {
    image: createrImage,
    title: "Highland Cow #179",
    company: "Good Morning Cafe",
    pecentage: " 10%",
    price: "0.003",
  },
  {
    image: createrImage,
    title: "Highland Cow #179",
    company: "Good Morning Cafe",
    pecentage: " 10%",
    price: "0.003",
  },
];
