import { Box, Typography } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React from "react";
import { makeStyles } from "@mui/styles";
import SingleCard from "../card/singleCard";
import FixPriceForm from "../fixPriceForm";
import { useSelector } from "react-redux";

const ListingDetails = () => {
  const classes = useStyles();

  const { singleArts } = useSelector((state) => state.arts);

  return (
    <Box className={classes.mainContainer}>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Typography className={classes.heading}>List for sale</Typography>
        </Box>
        <Box pt={3} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography className={classes.subHeading}>
            Pun On Fix Price
          </Typography>
          <ErrorOutlineIcon sx={{ color: "white", fontSize: "30px" }} />
        </Box>

        <Box>
          <FixPriceForm {...singleArts} />
        </Box>
      </Box>
      <Box>
        <SingleCard {...singleArts} />
      </Box>
    </Box>
  );
};

export default ListingDetails;
const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    gap: "20px",
    width: "100% !important",
    padding: 0,
  },
  mainContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "100%",
    gap: "20px",
    justifyItems: "center",
    padding: "20px 20px",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: " 1fr",
    },
  },
  heading: {
    fontSize: "40px !important",
    fontWeight: 800 + "!important",
    color: "white !important",
    textTransform: "capitalize",
    textAlign: "center",
  },
  subHeading: {
    fontSize: "20px !important",
    fontWeight: 500 + "!important",
    color: "white !important",
  },
}));
