import ReduxProvider from "./store/provider";
import { RouterProvider } from "react-router-dom";
import theme from "./theme";
import router from "./routes";
import { ThemeProvider } from "@mui/styles";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import { PolygonAmoyTestnet, OpSepoliaTestnet } from "@thirdweb-dev/chains";
import WalletConnect from "./hooks/thirdwebHooks/WalletConnect";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ThirdwebProvider
        supportedChains={[PolygonAmoyTestnet, OpSepoliaTestnet]}
        clientId={"a2b779af9c0c2715c4b24a765d2968c4"}
        activeChain={OpSepoliaTestnet}
      >
        <ReduxProvider>
          <WalletConnect>
            <RouterProvider router={router} />
          </WalletConnect>
        </ReduxProvider>
      </ThirdwebProvider>
    </ThemeProvider>
  );
}

export default App;
