import { Box, InputBase, Typography } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import verify from "../../../assets/icons/yellowVerify.svg";
import Button from "../../button";
import {
  checkEmail,
  checkUsername,
} from "../../../redux/profile/profile.action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const UserInformation = ({ formik }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isUsernameExists, isEmailExists, isUpdateInfo } = useSelector(
    (state) => state.profile
  );
  useEffect(() => {
    dispatch(checkUsername(formik.values.username));
  }, [dispatch, formik.values.username]);
  useEffect(() => {
    dispatch(checkEmail(formik.values.email));
  }, [dispatch, formik.values.email]);
  useEffect(() => {
    if (isUpdateInfo) {
      setTimeout(() => {
        navigate("/userprofile");
      }, 3000);
    }
  }, [navigate, isUpdateInfo]);

  return (
    <Box
      pt={4}
      sx={{
        display: "grid",
        gridTemplateColumns: {
          lg: "1fr 1fr",
          md: "1fr 1fr",
          sm: "1fr ",
          xs: "1fr",
        },
        gap: "30px",
        padding: {
          lg: "20px 0px",
          md: "20px 0px",
          sm: "10px 10px",
          xs: "10px 10px",
        },
      }}
      onClick={formik.handleChange}
    >
      <Box>
        <Box>
          <Typography py={1} sx={{ fontSize: "22px", fontWeight: 700 }}>
            Display Name
          </Typography>
          <InputBase
            placeholder="Enter your name"
            name="displayName"
            onChange={formik.handleChange}
            value={formik.values.firstName}
            sx={{
              border: "",
              background: "#313131",
              padding: "5px 20px",
              width: "100%",
              borderRadius: "10px",
              color: "#fff",
              fontSize: "14px",
              fontWeight: 400,
              "&::placeholder": {
                color: "rgba(255, 255, 255, 0.40)",
              },
            }}
          />
        </Box>
        <Box>
          <Typography py={1} sx={{ fontSize: "22px", fontWeight: 700 }}>
            Username
          </Typography>
          <InputBase
            placeholder="Enter your @username "
            name="username"
            onChange={formik.handleChange}
            value={formik.values.username}
            sx={{
              border: "",
              background: "#313131",
              padding: "5px 20px",
              width: "100%",
              borderRadius: "10px",
              color: "#fff",
              fontSize: "14px",
              fontWeight: 400,
              "&::placeholder": {
                color: "rgba(255, 255, 255, 0.40)",
              },
            }}
          />

          <>
            {isUsernameExists === true ? (
              <Typography
                sx={{ color: "green", fontSize: "14px", paddingLeft: 0.5 }}
              >
                The username is available for use.
              </Typography>
            ) : (
              <Typography sx={{ color: "red", fontSize: "14px", padding: 0.5 }}>
                Username is already in use.{" "}
                <span style={{ color: "green" }}>
                  Please choose a different username.
                </span>
              </Typography>
            )}
          </>
          <Typography
            py={1}
            sx={{
              fontSize: "13px",
              fontWeight: 500,
              color: "rgba(255, 255, 255, 0.60)",
            }}
          >
            Your profile will be available on rarible.com/[username]
          </Typography>
        </Box>{" "}
        <Box>
          <Typography py={1} sx={{ fontSize: "22px", fontWeight: 700 }}>
            Email
          </Typography>
          <InputBase
            placeholder="Enter your email"
            name="email"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.email}
            sx={{
              border: "",
              background: "#313131",
              padding: "5px 20px",
              width: "100%",
              borderRadius: "10px",
              color: "#fff",
              fontSize: "14px",
              fontWeight: 400,
              "&::placeholder": {
                color: "rgba(255, 255, 255, 0.40)",
              },
            }}
          />
          {formik.values.email && (
            <>
              {" "}
              {isEmailExists === true ? (
                <Typography
                  sx={{ color: "green", fontSize: "14px", paddingLeft: 0.5 }}
                >
                  The email is available for use.
                </Typography>
              ) : (
                <>
                  <Typography
                    sx={{
                      color: "red",
                      fontSize: "14px",
                      padding: 0.5,
                    }}
                  >
                    Email is already in use.
                    <span style={{ color: "green" }}>
                      {" "}
                      Please choose a different email address.
                    </span>
                  </Typography>
                </>
              )}
            </>
          )}
        </Box>
        <Box>
          <Typography py={1} sx={{ fontSize: "22px", fontWeight: 700 }}>
            Short Bio
          </Typography>
          <InputBase
            placeholder="Tell about your self in a few words..."
            name="shortBio"
            onChange={formik.handleChange}
            value={formik.values.bio}
            type="text"
            sx={{
              border: "",
              background: "#313131",
              padding: "5px 20px",
              width: "100%",
              borderRadius: "10px",
              color: "rgba(255, 255, 255, 0.40)",
              fontSize: "14px",
              fontWeight: 400,
            }}
          />
        </Box>{" "}
        <Box>
          <Typography py={1} sx={{ fontSize: "22px", fontWeight: 700 }}>
            Social Links
          </Typography>
          <Typography
            sx={{
              fontSize: "13px",
              fontWeight: 500,
              color: "rgba(255, 255, 255, 0.60)",
            }}
          >
            Add your existing social links to build a stronger reputation
          </Typography>
        </Box>
        <Box>
          <Typography py={1} sx={{ fontSize: "15px", fontWeight: 500 }}>
            Instagram
          </Typography>
          <InputBase
            placeholder="@"
            name="instagram"
            onChange={formik.handleChange}
            value={formik.values.instagram}
            type="text"
            sx={{
              border: "",
              background: "#313131",
              padding: "5px 20px",
              width: "100%",
              borderRadius: "10px",
              color: "rgba(255, 255, 255, 0.40)",
              fontSize: "14px",
              fontWeight: 400,
            }}
          />
        </Box>{" "}
        <Box>
          <Typography py={1} sx={{ fontSize: "15px", fontWeight: 700 }}>
            X (Twitter)
          </Typography>
          <InputBase
            placeholder="Enter your X username"
            name="twitter"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.twitter}
            startAdornment={
              <XIcon
                sx={{ color: "#fff", fontSize: "14px", marginRight: "5px" }}
              />
            }
            endAdornment={
              <span style={{ color: "#fff", fontWeight: "bold" }}>Link</span>
            }
            sx={{
              border: "",
              background: "#313131",
              padding: "5px 20px",
              width: "100%",
              borderRadius: "10px",
              color: "rgba(255, 255, 255, 0.40)",
              fontSize: "14px",
              fontWeight: 400,
              "&::placeholder": {
                paddingLeft: "10px",
              },
            }}
          />
        </Box>
        <Box pt={2.5}>
          <Button onClick={formik.handleSubmit} variant="contained">
            Save Changes
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          background: "rgba(255, 255, 255, 0.10)",
          padding: "20px",
          maxWidth: "220px",
          display: { lg: "flex", md: "none", sm: "none", xs: "none" },
          flexDirection: "column",
          borderRadius: "10px",
          maxHeight: "300px",
        }}
      >
        <Box
          sx={{
            background: "white",
            borderRadius: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 30px",
          }}
        >
          <img src={verify} width="100px" alt="" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Box>
            {" "}
            <Typography pt={0.5} sx={{ fontSize: "18px", fontWeight: 600 }}>
              Verify your account
            </Typography>
            <Typography
              pt={0.5}
              sx={{
                fontSize: "13px",
                fontWeight: 400,
                color: "rgba(255, 255, 255, 0.60)",
              }}
            >
              Proceed with verification process to get more visibility and gain
              trust on Rarible
            </Typography>
          </Box>
          <Button pt={2} variant="secondaryOutlined">
            Get Verified
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default UserInformation;
