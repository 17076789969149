import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { data } from "./data";
import CardTrending from "./index";
import Button from "../../button";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
const TrendingCard = () => {
  const classes = useStyles();
  return (
    <Box sx={{ padding: { lg: "10px 50px", md: "5px 30px", xs: "0px 20px" } }}>
      <Box
        textAlign="center"
        sx={{ padding: { lg: 4, md: 3, sm: 0 }, pt: { xs: 1 } }}
      >
        <Typography className={classes.heading}>
          <span className={classes.span}>Trendy</span>&nbsp;Collections{" "}
          <span className={classes.span}>by</span> Networks
        </Typography>
      </Box>

      <Box className={classes.flexContainer}>
        {" "}
        {data &&
          data.map((data, i) => {
            return <CardTrending key={i} {...data} />;
          })}
      </Box>
      <Box py={2}>
        <Button
          className={classes.button}
          variant="outlined"
          endIcon={<ArrowForwardIcon />}
        >
          More
        </Button>
      </Box>
    </Box>
  );
};

export default TrendingCard;
const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "1rem",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  heading: {
    fontSize: "36px !important",
    fontWeight: 800,
    color: "#fff",
    textAlign: "center",
  },
  span: {
    color: "#C0D709",
  },
  button: {
    width: "100% !important",
    borderRadius: "11px",
    background: "#191919 !important",
    textTransform: "capitalize !important",
    border: "none !important",
  },
}));
