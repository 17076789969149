import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FavoriteBorderOutlined } from "@mui/icons-material";
import NFTCard from "./index";
import Button from "../../button";
import Carousel from "react-elastic-carousel";
import { CarouselArrow } from "../../../utils";
import { useSelector } from "react-redux";
const CollectionCard = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 },
    { width: 1350, itemsToShow: 5 },
  ];
  const { AllArts } = useSelector((state) => state.arts);
  const classes = useStyles();
  return (
    <Box>
      <Box
        textAlign="center"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography className={classes.heading}>
          <span className={classes.span}>HeadHunters</span>&nbsp;Stakeable NFT
          Collections on <span className={classes.span}>PulseChain</span>
        </Typography>
      </Box>
      <Box className={classes.flexContainer}>
        <Carousel
          autoPlaySpeed={5000}
          enableAutoPlay
          disableArrowsOnEnd={false}
          breakPoints={breakPoints}
          infinite={true}
          pagination={false}
          renderArrow={(arrowProps) => <CarouselArrow {...arrowProps} />}
          style={{ padding: "0 !important" }}
        >
          {AllArts &&
            AllArts.map((items, i) => {
              return (
                <NFTCard
                  key={i}
                  {...items}
                  favIcon={
                    <FavoriteBorderOutlined sx={{ color: "#FFFFFF80" }} />
                  }
                />
              );
            })}
        </Carousel>
      </Box>

      <Box>
        <Button className={classes.button} variant="outlined">
          Stake HHS NFTs
        </Button>
      </Box>
    </Box>
  );
};

export default CollectionCard;

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: "flex",
    // gap: "1rem",
    flexWrap: "wrap",
    // justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
  heading: {
    fontSize: "36px !important",
    fontWeight: 800 + "!important",
    color: "#fff",
    padding: 4,
    [theme.breakpoints.down("md")]: {
      width: "100% !important",
      padding: 0,
    },
  },
  span: {
    color: "#C0D709",
  },
  button: {
    width: "100% !important",
    borderRadius: "11px",
    background: "rgba(255, 255, 255, 0.04) !important",
    textTransform: "capitalize !important",
  },
}));
