import { Box, Typography } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import worldIcon from "../../assets/icons/world.png";
import discordIcon from "../../assets/icons/discord.png";
import eScanIcon from "../../assets/icons/Ether.png";
import { useParams } from "react-router-dom";
const Links = () => {
  return (
    <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
      <Box
        sx={{
          background: "#1B1B1E",
          padding: "10px 15px",
          borderRadius: "5px 0 0 5px",
          cursor: "pointer",
        }}
      >
        <img src={eScanIcon} alt="eScan" width="15px" />
      </Box>
      <Box
        sx={{
          background: "#1B1B1E",
          padding: "10px 15px",
          cursor: "pointer",
        }}
      >
        <TwitterIcon sx={{ color: "white", fontSize: "16px" }} />
      </Box>
      <Box
        sx={{ background: "#1B1B1E", padding: "10px 15px", cursor: "pointer" }}
      >
        <img src={discordIcon} alt="discord" width="15px" />
      </Box>
      <Box
        sx={{
          background: "#1B1B1E",
          padding: "10px 15px",
          borderRadius: "0px 5px 5px 0px",
          cursor: "pointer",
        }}
      >
        <img src={worldIcon} alt="world" width="15px" />
      </Box>
    </Box>
  );
};

export default Links;

//////////////////////////////////////////////////

export const ArtBlockchainDetails = () => {
  const address = "0x1c595099456179bF9B820D49aD504c200E1c7822";
  const shortenedAddress =
    address.substring(0, 4) + "..." + address.substring(address.length - 4);
  const myChain = "Ethereum";
  const { tokenId } = useParams();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      pt={4}
    >
      <Box>
        <Box>
          {" "}
          <Typography
            sx={{ color: "#C0D709", fontSize: "12px", fontWeight: 400 }}
          >
            Contract Address
          </Typography>
          <Typography
            sx={{ color: "#C0D709", fontSize: "12px", fontWeight: 400 }}
          >
            Chain{" "}
          </Typography>
          <Typography
            sx={{ color: "#C0D709", fontSize: "12px", fontWeight: 400 }}
          >
            Token ID
          </Typography>
          <Typography
            sx={{ color: "#C0D709", fontSize: "12px", fontWeight: 400 }}
          >
            Token Standards
          </Typography>
          <Typography
            sx={{ color: "#C0D709", fontSize: "12px", fontWeight: 400 }}
          >
            Creator Royalties
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "30px", color: "#A1A1AA" }}>
          <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
            Activity
          </Typography>
          <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
            Listings
          </Typography>
          <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
            Offers
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography sx={{ color: "transparent" }}>.</Typography>
        <Typography
          sx={{ color: "#C0D709", fontSize: "12px", fontWeight: 500 }}
        >
          {shortenedAddress}
        </Typography>
        <Typography sx={{ color: "#fff", fontSize: "12px", fontWeight: 700 }}>
          {myChain}
        </Typography>
        <Typography sx={{ color: "#fff", fontSize: "12px", fontWeight: 700 }}>
          {tokenId}
        </Typography>
        <Typography sx={{ color: "#fff", fontSize: "12px", fontWeight: 700 }}>
          ERC721
        </Typography>
        <Typography sx={{ color: "#fff", fontSize: "12px", fontWeight: 700 }}>
          10%
        </Typography>
      </Box>
    </Box>
  );
};
