import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";

export const CustomTabs = ({ label, index, value, ...props }) => {
  const classes = useStyles();
  return (
    <Box {...props} className={classes.tab}>
      <Typography
        variant="body1"
        fontWeight={600}
        className={clsx(classes.label, { [classes.selected]: index === value })}
        sx={{ color: index === value ? "#fff" : "grey" }}
      >
        {label}
      </Typography>
    </Box>
  );
};

export const Tabs = ({ value, children, onChange, className }) => {
  const classes = useStyles();
  const handleClick = (index) => {
    onChange(index);
  };
  const childrenWithProps = React.Children.map(children, (child, index) =>
    React.cloneElement(child, {
      value: value,
      onClick: handleClick.bind(this, index),
      index: index,
    })
  );
  return (
    <Box className={clsx(className, classes.root)}>{childrenWithProps}</Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px 30px",
    backgroundColor: "none !important",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      gap: "20px",

      padding: "10px 10px",
    },
  },
  tabs: {
    width: "100%",
  },
  selected: {
    position: "relative",
    borderRadius: "10px",
    color: "#FFF !important",
    "&::after": {
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      height: 5,
      content: "''",
      backgroundImage: "#fff",
      transition: ".25s",
    },
  },
  label: {
    fontFamily: "Montserrat,sans-serif",
    textDecoration: "none",
    fontWeight: 700,
    padding: "5px 0px 10px 0px",
    position: "relative",
    cursor: "pointer",
    width: "fit-content",

    "&::after": {
      position: "absolute",
      bottom: 5,
      left: "50%",
      transform: "translateX(-50%)",
      height: 0,
      content: "''",
      // backgroundColor: "grey !important",
      transition: ".25s",
      color: "grey !important",
    },
  },
}));
