import { Box, Typography } from "@mui/material";
import uploadIcon from "../../assets/icons/upload.png";
import { makeStyles } from "@mui/styles";
import { FileUploader } from "react-drag-drop-files";

const UploadIcon = ({ formik }) => {
  const classes = useStyles();
  const handleChange = (file) => {
    formik.setFieldValue("image", file);
  };
  return (
    <FileUploader handleChange={handleChange}>
      <Typography className={classes.title}>
        Add NFT image <span>*</span>
      </Typography>
      <Box className={classes.imageContainer}>
        <img src={uploadIcon} alt="nft-Upload" />
      </Box>
    </FileUploader>
  );
};
export default UploadIcon;
const useStyles = makeStyles((theme) => ({
  input: {
    display: "none !important",
  },
  imageContainer: {
    border: "1px dashed #E3FF00",
    width: "150px !important",
    borderRadius: "10px !important",
    cursor: "pointer",
    display: "flex !important",
    justifyContent: "center",
  },
  title: {
    fontSize: "24px",
    fontWeight: 400 + "!important",
    paddingBottom: "10px",
    "& span": {
      color: "#AF0A0A",
    },
  },
}));
