import {
  Box,
  Button,
  IconButton,
  ListItem,
  ListItemText,
  SwipeableDrawer,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useState } from "react";
import Logo from "../../assets/images/logo.png";
import MenuIcon from "../menuIcon";
import { makeStyles } from "@mui/styles";
import search from "../../assets/icons/search.svg";
import CartIcon from "../../assets/icons/cart.png";
import { useNavigate } from "react-router-dom";

const HamBurger = ({ toggleDrawer, handleOpen }) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const [open, setOpen] = useState();

  function handleHome() {
    navigate("/");
    setOpen(!true);
  }
  function handleCreate() {
    navigate("/create");
    setOpen(!true);
  }
  function handleBrowse() {
    navigate("/browse");
    setOpen(!true);
  }
  function handleMyWallet() {
    navigate("/userprofile");
    setOpen(!true);
  }
  function handleBlog() {
    navigate("/blog");
    setOpen(!true);
  }
  function handleCart() {
    setOpen(!true); // Close the current drawer
    toggleDrawer(true); // Open the cart drawer
  }
  function handleConnect() {
    handleOpen();
    setOpen(!true);
  }
  return (
    <Box sx={{ display: { lg: "none", md: "none", sm: "block", xs: "block" } }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <img src={Logo} width="70px" alt="" />
        </Box>
        <Box>
          <IconButton
            edge="start"
            fontSize="large"
            onClick={() => {
              setOpen(true);
            }}
          >
            <MenuIcon color="#E3FF00" width={55} height={30} />
          </IconButton>
        </Box>
      </Box>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onOpen={() => {}}
        className={classes.swipeableDrawer}
      >
        <Box p={2}>
          <Box onClick={handleHome} className={classes.container}>
            {" "}
            <img src={Logo} width="70px" alt="" />
            <CancelIcon onClick={() => setOpen(false)} />
          </Box>
          <hr />
          <Box display="flex" flexDirection="column" gap="20px" pt={2}>
            <ListItem disablePadding>
              <Button
                sx={{
                  border: "2px dashed #E3FF00",
                  borderRadius: "11px",
                  textTransform: "capitalize",
                  fontWeight: "400",
                  padding: "2px 15px",
                  borderWidth: "1px 1px 1px 1px",
                  color: "white",

                  "&:hover": {
                    border: "1px solid black",
                    backgroundColor: "#E3FF00",
                    color: "#000 !important",
                    transition:
                      "border-color 0.7s, background-color 0.7s, color 0.7s",
                  },
                }}
                onClick={handleConnect}
              >
                Connect Wallet
              </Button>
            </ListItem>
            <ListItem
              disablePadding
              onClick={handleCreate}
              className={classes.listItem}
            >
              <ListItemText primary="Creator" />
            </ListItem>
            <ListItem
              onClick={handleBrowse}
              disablePadding
              className={classes.listItem}
            >
              <ListItemText primary="Browse" />
            </ListItem>
            <ListItem
              onClick={handleBlog}
              disablePadding
              className={classes.listItem}
            >
              <ListItemText primary="Blog" />
            </ListItem>
            <ListItem
              onClick={handleMyWallet}
              disablePadding
              className={classes.listItem}
            >
              <ListItemText primary="My Wallet" />
            </ListItem>
            <ListItem disablePadding>
              <img src={search} alt="" width="25px" />
            </ListItem>
            <ListItem sx={{ padding: "0" }}>
              <IconButton
                sx={{ padding: "0 !important", marginRight: "10px !important" }}
                onClick={() => {
                  handleCart();
                }}
              >
                <img
                  src={CartIcon}
                  width="50px"
                  height="30px"
                  alt="Cart-Icon"
                  // style={{ marginRight: "20px" }}
                />
                <span className={classes.span}>1</span>
              </IconButton>
            </ListItem>
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};
export default HamBurger;
const useStyle = makeStyles((theme) => ({
  swipeableDrawer: {
    // background: "black",
    "& .MuiPaper-root": {
      background: "#000",
      width: "100%",
      color: "#C7D921",
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  listItem: {
    cursor: "pointer",
    "&:hover": {
      borderBottom: "1px solid #C7D921",
    },
  },
  span: {
    backgroundColor: "#F00",
    borderRadius: "50%",
    position: "absolute",
    fontSize: "10px",
    color: "#fff",
    padding: "3px",
    bottom: "17px",
    right: "5px",
    fontWeight: "bold",
  },
}));
