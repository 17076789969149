import React from "react";
import { Paper, Stack, Typography } from "@mui/material";
import CardCollectionTabs from "../tableTabs/cardCollectionTabs";
import { makeStyles } from "@mui/styles";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Button from "../../button";
import { TableBodyData, tableHeadData } from "../TableMain/tableData";

const tabContents = [
  { tableColumnData: tableHeadData, tableRowsData: TableBodyData },
  { tableColumnData: tableHeadData, tableRowsData: TableBodyData },
  { tableColumnData: tableHeadData, tableRowsData: TableBodyData },
  { tableColumnData: tableHeadData, tableRowsData: TableBodyData },
  { tableColumnData: tableHeadData, tableRowsData: TableBodyData },
];

export const TableContainer = () => {
  const classes = useStyles();

  return (
    <Paper variant="outlined" className={classes.tableContainer}>
      <Typography variant="h6" className={classes.tableCaption}>
        Trending Collections
      </Typography>

      <Stack direction="row" gap={2}>
        <CardCollectionTabs tabContents={tabContents} />
      </Stack>

      <Button
        endIcon={<NavigateNextIcon />}
        variant="contained"
        fullWidth
        className={classes.tableButton}
      >
        View all collections
      </Button>
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    backgroundColor: "transparent !important",
    borderColor: "#FFFFFF63 !important",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
  },

  tableCaption: {
    color: "#FFFFFF",
    borderBottom: "1px solid #FFFFFF63",
    paddingBottom: "0.5rem",
  },

  tableButton: {
    backgroundColor: "#FFFFFF20 !important",
    color: "white !important",
    "&:hover": {
      backgroundColor: "#FFFFFF40!important",
    },
  },
}));
