import React from "react";
import { Avatar, TableRow, Stack, Typography, TableCell } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  currencyColor: {
    color: "#FFFFFF99",
  },
  positiveValue: {
    color: "#28B833",
  },
  negativeValue: {
    color: "#E94949",
  },
  tableBodyCells: {
    color: "#EDEDED !important",
    borderBottomColor: "#FFFFFF63 !important",
    [theme.breakpoints.down("sm")]: {
      padding: "10px !important",
      "& .MuiTableCell-root ": {},
    },
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#FFFFFF20  !important",
    },
  },
  nftName: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px !important",
    },
  },
}));

const TableBodyRow = ({
  id,
  imageAlt,
  imageUrl,
  nftName,
  floorPrice,
  currencyType,
  floorChange,
  volume,
  volumeChange,
  items,
  owners,
}) => {
  const classes = useStyles();

  const renderTableCell = (value, align = "left") => (
    <TableCell align={align} className={classes.tableBodyCells}>
      {value}
    </TableCell>
  );

  const getTextColorClass = (value) => {
    const numericValue = parseFloat(value);
    return numericValue < 0 ? classes.negativeValue : classes.positiveValue;
  };

  return (
    <TableRow hover tabIndex={-1} key={id} className={classes.tableRow}>
      {renderTableCell(id)}
      {renderTableCell(
        <Stack direction="row" spacing={1} alignItems="center">
          <Avatar
            sx={{
              width: { sm: 30, md: 40 },
              height: { sm: 30, md: 40 },
            }}
            variant="rounded"
            alt={imageAlt}
            src={imageUrl}
          />
          <Typography className={classes.nftName}>{nftName}</Typography>
        </Stack>,
        "left"
      )}
      {renderTableCell(
        <Stack>
          <Typography>{floorPrice}</Typography>
          <Typography className={classes.currencyColor}>
            {currencyType}
          </Typography>
        </Stack>,
        "right"
      )}
      {renderTableCell(
        <Typography className={getTextColorClass(floorChange)}>
          {floorChange > 0 ? `+${floorChange}%` : `${floorChange}%`}
        </Typography>,
        "right"
      )}
      {renderTableCell(
        <Stack>
          <Typography>{volume}</Typography>
          <Typography className={classes.currencyColor}>ETH</Typography>
        </Stack>,
        "right"
      )}
      {renderTableCell(
        <Typography className={getTextColorClass(volumeChange)}>
          {volumeChange > 0 ? `+${volumeChange}%` : `${volumeChange}%`}
        </Typography>,
        "right"
      )}
      {renderTableCell(items, "right")}
      {renderTableCell(owners, "right")}
    </TableRow>
  );
};

export default TableBodyRow;
