import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    backgroundColor: "#0F0F0F",
    padding: "25px",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "20px",
    minHeight: "60vh",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  filtersContainer: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  container: {
    gap: "20px",
    background: "#313131",
    padding: "10px 25px",
    display: "flex",
    borderRadius: "10px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobileFilter: {
    display: "none",

    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
}));
