import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Image from "../../assets/images/Rectangle 36 (1).png";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "rgba(255, 255, 255, 0.1) !important",
    color: "#fff !important",
    width: "100% !important",
  },
  title: {
    marginBottom: theme.spacing(1),
    fontSize: "24px !important",
    fontWeight: 500 + "!important",
  },
  content: {
    whiteSpace: "pre-wrap",
  },
  paper: {
    backgroundColor: "transparent !important",
  },
}));

const BlogCard = ({ title, content }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent className={classes.paper}>
        <Box>
          {" "}
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <img
            src={Image}
            width={"100px"}
            height={"100%"}
            style={{ objectFit: "cover" }}
            alt=""
          />
          <Typography variant="body1" className={classes.content}>
            {content}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BlogCard;
