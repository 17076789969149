import { createSlice } from "@reduxjs/toolkit";
import { directTransfer } from "./transfer.action";

const initialState = {
  isDirectTransferLoading: false,
  isDirectTransferSuccess: false,
  isDirectTransferFailed: false,
  successMessage: {},
  errorMessage: {},
};

const directTransferSlicer = createSlice({
  name: "transfer",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(directTransfer.pending, (state) => {
      state.isDirectTransferLoading = true;
    });
    builder.addCase(directTransfer.fulfilled, (state, action) => {
      state.isDirectTransferLoading = false;
      state.isDirectTransferSuccess = true;
      state.successMessage = action.payload;
    });
    builder.addCase(directTransfer.rejected, (state, action) => {
      state.isDirectTransferLoading = false;
      state.isDirectTransferSuccess = false;
      state.isDirectTransferFailed = true;
      state.errorMessage = action.error.message;
    });
  },
});

export default directTransferSlicer.reducer;
