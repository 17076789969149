import { createSlice } from "@reduxjs/toolkit";
import { putOnFixedPrice } from "./listing.action";

const initialState = {
  isPutOnFixPriceLoading: false,
  isPutOnFixPriceSuccess: false,
  isPutOnFixPriceFailed: false,
  successMessage: {},
  errorMessage: {},
};

const putOnFixedPriceSlicer = createSlice({
  name: "listing",
  initialState: initialState,
  reducers: {
    resetFixPrice: (state) => {
      state.isPutOnFixPriceLoading = false;
      state.isPutOnFixPriceSuccess = false;
      state.isPutOnFixPriceFailed = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
    resetAuction: (state) => {
      state.isPutOnAuctionLoading = false;
      state.isPutOnAuctionSuccess = false;
      state.isPutOnAuctionFailed = false;
      state.errorMessage = "";
      state.successMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(putOnFixedPrice.pending, (state) => {
      state.isPutOnFixPriceLoading = true;
    });
    builder.addCase(putOnFixedPrice.fulfilled, (state, action) => {
      state.isPutOnFixPriceLoading = false;
      state.isPutOnFixPriceSuccess = true;
      state.successMessage = action.payload.message;
    });
    builder.addCase(putOnFixedPrice.rejected, (state, action) => {
      state.isPutOnFixPriceLoading = false;
      state.isPutOnFixPriceSuccess = false;
      state.isPutOnFixPriceFailed = true;
      state.errorMessage = action.error.message;
    });
  },
});

export default putOnFixedPriceSlicer.reducer;
export const { resetFixPrice, resetAuction } = putOnFixedPriceSlicer.actions;
