import { Box, IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import search from "../../assets/icons/search.svg";
import CartIcon from "../../assets/icons/cart.png";
import HamBurger from "../hamBurger";
import React from "react";
import TemporaryDrawer from "../cartDrawer";
import WalletModal from "../walletModal";
import { ConnectWallet } from "@thirdweb-dev/react";

const Header = () => {
  const [cartOpen, setCartOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setCartOpen(newOpen);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [open, setOpen] = React.useState(false);

  return (
    <Box
      sx={{
        backgroundColor: "rgba(255, 255, 255, 0.10)",

        backdropFilter: "blur(25px)",
      }}
    >
      <Box
        sx={{
          display: { lg: "flex", md: "flex", sm: "none", xs: "none" },
          alignItems: "center",
          justifyContent: "space-between",
        }}
        px={2.5}
      >
        {" "}
        <Box
          display="flex"
          alignItems="center"
          component={Link}
          to="/"
          sx={{ textDecoration: "none", padding: "20px 0px 20px 11px " }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 500,
              textDecoration: "none",
              color: "white",
            }}
          >
            Home
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" gap="40px">
          <Typography
            component={Link}
            to="/create"
            variant="body1"
            color="#FFF"
            sx={{ textDecoration: "none" }}
          >
            Create
          </Typography>
          <Typography
            component={Link}
            to="/browse"
            variant="body1"
            color="white"
            sx={{ textDecoration: "none" }}
          >
            Browse
          </Typography>
          <Typography
            component={Link}
            to="/blog"
            variant="body1"
            color="white"
            sx={{ textDecoration: "none" }}
          >
            Blog
          </Typography>
          <Typography
            component={Link}
            to="/userprofile"
            variant="body1"
            color="white"
            sx={{ textDecoration: "none" }}
          >
            My Wallet
          </Typography>

          <IconButton sx={{ padding: "0" }}>
            <img src={search} alt="" width="25px" />
          </IconButton>
          {/* <Button
            sx={{
              border: "2px dashed #E3FF00",
              borderRadius: "11px",
              textTransform: "capitalize",
              fontWeight: "400",
              padding: "2px 15px",
              borderWidth: "1px 1px 1px 1px",
              "&:hover": {
                border: "1px solid black",
                backgroundColor: "#E3FF00",
                color: "#000 !important",
                transition:
                  "border-color 0.7s, background-color 0.7s, color 0.7s",
              },
            }}
            onClick={handleOpen}
          >
            Connect Wallet
          </Button> */}
          <ConnectWallet />
          <IconButton sx={{ padding: "0" }} onClick={toggleDrawer(true)}>
            <img src={CartIcon} width="50px" height="30px" alt="Cart-Icon" />
            <span
              style={{
                backgroundColor: "#F00",
                borderRadius: "50%",
                position: "absolute",
                fontSize: "10px",
                color: "#fff",
                padding: "3px",
                bottom: "17px",
                right: "5px",
                fontWeight: "bold",
              }}
            >
              1
            </span>
          </IconButton>
        </Box>
      </Box>
      <Box>
        <HamBurger
          toggleDrawer={toggleDrawer}
          cartOpen={cartOpen}
          handleOpen={handleOpen}
        />
      </Box>
      <Box sx={{ height: "100%" }}>
        <TemporaryDrawer toggleDrawer={toggleDrawer} cartOpen={cartOpen} />
      </Box>
      <Box>
        <WalletModal open={open} handleClose={handleClose} />
      </Box>
    </Box>
  );
};
export default Header;
