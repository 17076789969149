import { createAsyncThunk } from "@reduxjs/toolkit";
import { mintApi } from "./api";

export const mint = createAsyncThunk("mint", async (payload) => {
  try {
    const res = await mintApi(payload);
    return res.data;
  } catch (error) {
    throw error.response.data.message;
  }
});
