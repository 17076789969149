import { Box, Typography } from "@mui/material";
import BlogCard from "../../components/blogCard";
import backgroundImage from "../../assets/images/backgroungImage.png";

const Blog = () => {
  const blogData = [
    {
      title: "Real examples of stack",
      content:
        "There are many real examples of stackConsider an example of plates stacked over one another in the cartoonThe plate which is at the top is the first one to be removed,It is the place which has been placed at the bottommost positionfor the longest period of time",
    },
    {
      title: "Algorithm",
      content:
        "Algorithm is a set of rules to be followed in calculations\nor other problem-solving operations, especially by a computer\n",
    },
    {
      title: "Real examples of stack",
      content:
        "There are many real examples of stackConsider an example of plates stacked over one another in the cartoonThe plate which is at the top is the first one to be removed,It is the place which has been placed at the bottommost positionfor the longest period of time",
    },
    {
      title: "Algorithm",
      content:
        "Algorithm is a set of rules to be followed in calculations\nor other problem-solving operations, especially by a computer\n",
    },
    {
      title: "Real examples of stack",
      content:
        "There are many real examples of stackConsider an example of plates stacked over one another in the cartoonThe plate which is at the top is the first one to be removed,It is the place which has been placed at the bottommost positionfor the longest period of time",
    },
    {
      title: "Algorithm",
      content:
        "Algorithm is a set of rules to be followed in calculations\nor other problem-solving operations, especially by a computer\n",
    },
  ];
  return (
    <Box
      sx={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundColor: "black",
        zIndex: 1000,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: {
          lg: "20px 35px",
          md: "20px 35px",
          sm: "10px 25px",
          xs: "5px 15px",
        },
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        minHeight: "85vh",
      }}
    >
      <Box textAlign="center" p={2}>
        <Typography
          sx={{ color: "#C7D921", fontSize: "34px", fontWeight: 700 }}
        >
          Blog
        </Typography>
      </Box>
      <Box
        sx={{
          display: "grid",
          gap: { lg: 5, md: 2, sm: 2, xs: 2 },
          gridTemplateColumns: {
            lg: "1fr 1fr",
            md: "1fr 1fr",
            sm: "1fr",
            xs: "1fr",
          },
        }}
        pb={2}
      >
        {blogData.map((entry, index) => (
          <BlogCard key={index} title={entry.title} content={entry.content} />
        ))}
      </Box>
    </Box>
  );
};

export default Blog;
