import { useFormik } from "formik";
import { useStyles } from "./style";
import ImageUpload from "../../../components/imageUpload";
import { Box, CircularProgress, InputBase, Typography } from "@mui/material";
import UploadIcon from "../../../components/uploadIcon";
import AddIcon from "@mui/icons-material/Add";
import NFTDetails from "../../../components/nftDetails";
import PreviewImage from "../../../components/imagePreview";
import Button from "../../../components/button";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { mint } from "../../../redux/mint/mint.action";
import { useDispatch, useSelector } from "react-redux";
import { useAddress, useChainId, useContract } from "@thirdweb-dev/react";
import { useNavigate } from "react-router-dom";
import {
  nftContractAddress,
  nftContractType,
} from "../../../hooks/thirdwebHooks/contractAddresses";
import Notifications from "../../../components/notification";
const SingleMinting = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const { isMintSuccess } = useSelector((state) => state.mint);
  const { contract } = useContract(nftContractAddress, nftContractType);
  const wallet = useAddress();
  const chainId = useChainId();

  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      image: "",
      title: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setSubmitting(true);

        const data = {
          name: values.title,
          description: values.description,
          image: values.image,
        };
        const tx = await contract.erc721.mintTo(wallet, data);
        console.log(tx, "tx");
        const transactionHash = tx.receipt.transactionHash; // the transaction receipt with hash
        const token = tx.id; // the id of the NFT minted
        const nft = await tx.data();
        console.log(nft, "nft");
        const metadata = nft.metadata;
        console.log(transactionHash, "transactionHash");
        let tokenId = parseInt(token._hex, 16);
        console.log(tokenId, "token");

        const apiPayload = {
          title: values.title,
          description: values.description,
          creatorWallet: wallet,
          tokenId: tokenId,
          properties: ["red", "blue"],
          amount: 1,
          metadata: metadata.uri,
          transactionHash: transactionHash,
          image: metadata.image,
          categoryId: 1,
          chainId: chainId,
        };

        dispatch(mint(apiPayload));
      } catch (error) {
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (isMintSuccess) {
      setTimeout(() => {
        navigate("/userprofile");
      }, 3400);
    }
  }, [isMintSuccess, navigate]);
  const classes = useStyles();
  return (
    <Box className={classes.mainContainer}>
      <Notifications />
      <Box className={classes.leftContainer}>
        <Box>
          <UploadIcon formik={formik} value={formik.values.image} />
        </Box>
        <Box className={classes.container}>
          <Box>
            <ImageUpload
              formik={formik}
              value={formik.values.image}
              helperText={
                (formik.touched.image && formik.errors.image) || `&nbsp;`
              }
              error={formik.touched.image && Boolean(formik.errors.image)}
            />
          </Box>
          <Box>
            <Typography className={classes.title}>
              Name <span>*</span>
            </Typography>
            <InputBase
              placeholder="eg. Name your NFT“"
              className={classes.nameInput}
              name="title"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Box>
          <Box>
            <Typography className={classes.title}>
              Description<span>*</span>
            </Typography>
            <Typography className={classes.description}>
              The description will be included on the item’s detail page
              underneath it’s image.
            </Typography>
            <textarea
              placeholder="eg. “Description your NFT”"
              className={classes.textArea}
              name="description"
              type="text"
              rows={7}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Box>
          <Box className={classes.propertiesContainer}>
            <Box>
              <Typography> Properties</Typography>
              <Typography className={classes.description}>
                Textual traits that show up as rectangles
              </Typography>
            </Box>
            <Box pr={2}>
              <AddIcon className={classes.icon} />
            </Box>
          </Box>
          <Box>
            <Button
              variant="contained"
              onClick={formik.handleSubmit}
              className={classes.button}
            >
              {submitting ? (
                <CircularProgress sx={{ color: "#000" }} size={"18px"} />
              ) : (
                "Mint NFT"
              )}
            </Button>
          </Box>
          <Box>
            <NFTDetails />
          </Box>
        </Box>
      </Box>
      <Box className={classes.rightContainer}>
        <PreviewImage
          formik={formik}
          value={formik.values.image}
          name={formik.values.title}
          description={formik.values.description}
        />
      </Box>
    </Box>
  );
};
export default SingleMinting;
const validationSchema = Yup.object().shape({
  image: Yup.string().required("Please upload an NFT image"),
  title: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
});
