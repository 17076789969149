export const privacyPolicyData = [
  {
    title: "Acceptance of Terms:",
    content:
      "By accessing or using the Platform, you agree to these Terms of Service and any additional terms and conditions that may apply to specific features or services offered on the Platform.",
  },
  {
    title: "Use of the Platform:",
    content:
      "You must be of legal age in your jurisdiction to use the Platform. By using the Platform, you affirm that you are of legal age and have the capacity to enter into these Terms of Service.",
  },
  {
    title: "User Responsibilities:",
    content:
      "Users are solely responsible for their interactions and transactions on the Platform. This includes conducting their own due diligence before engaging in any transactions. Users agree to comply with all applicable laws and regulations in their jurisdiction.",
  },
  {
    title: "Decentralized Nature:",
    content:
      "The Platform operates on a decentralized blockchain network. Users acknowledge that there is no central entity responsible for transactions, and they engage in peer-to-peer transactions facilitated by smart contracts on the blockchain.",
  },
  {
    title: "Privacy Policy:",
    content: "",
    subItems: [
      {
        subTitle: "1. Information Collection:",
        subContent:
          "1.1 Personal Information: The Platform does not collect, store, or process any personal information about its users.\n1.2 Non-Personal Information: The Platform may collect non-personal information, such as device information, browser type, and usage statistics.",
      },
      {
        subTitle: "2. Use of Information:",
        subContent:
          "The non-personal information collected is used for statistical analysis and to enhance the user experience on the Platform. This information is not shared with third parties and is solely used for internal purposes.",
      },
      {
        subTitle: "3. Data Storage:",
        subContent:
          "NFT Hunters Marketplace does not store any user data on its servers.",
      },
      {
        subTitle: "4. Cookies:",
        subContent:
          "The Platform does not use cookies or similar tracking technologies.",
      },
      {
        subTitle: "5. Third-Party Links:",
        subContent:
          "The Platform may contain links to third-party websites or services. This Privacy Policy does not apply to those third-party sites.",
      },
      {
        subTitle: "6. Security:",
        subContent:
          "While NFT Hunters Marketplace takes reasonable measures to ensure the security of the Platform, users should be aware that no method of transmission over the internet or electronic storage is completely secure.",
      },
    ],
  },
  {
    title: "Legal Disclaimer",
    content:
      "By accessing and using this decentralized marketplace frontend, you acknowledge and agree to the following terms:\n",
    subItems: [
      {
        subTitle: "1. Decentralized Nature:",
        subContent:
          "This marketplace operates on a decentralized blockchain network. There is no central entity, intermediary, or third party involved in the facilitation of transactions.",
      },
      {
        subTitle: "2. No Intermediary Responsibility:",
        subContent:
          "This frontend serves as a user interface to interact with the underlying smart contracts on the blockchain. It does not act as an intermediary or middle entity in any transaction. Users are solely responsible for their actions and transactions on this platform.",
      },
      {
        subTitle: "3. P2P Transactions:",
        subContent:
          "The marketplace facilitates peer-to-peer (P2P) transactions. Buyers and sellers engage directly with each other through the smart contracts. Users should exercise caution and conduct their own due diligence before participating in any transaction.",
      },
      {
        subTitle: "4. Risk and Responsibility:",
        subContent:
          "Users acknowledge that engaging in transactions on a decentralized marketplace involves inherent risks. There is no centralized authority to resolve disputes, and transactions are irreversible. Users are encouraged to thoroughly understand the terms of each smart contract and assess the risks associated with each transaction.",
      },
      {
        subTitle: "5. No Guarantee of Performance:",
        subContent:
          "The decentralized nature of this marketplace means that no guarantees can be made regarding the performance, availability, or reliability of the platform.",
      },
      {
        subTitle: "6. Legal Compliance:",
        subContent:
          "Users are responsible for ensuring that their activities on this platform comply with applicable laws and regulations in their jurisdiction.",
      },
      {
        subTitle: "7. No Warranty or Liability:",
        subContent:
          "This marketplace frontend is provided 'as is' without any warranty. The developers and operators of this frontend disclaim all liability for any loss, damage, or inconvenience incurred by users in connection with the use of this platform.",
      },
      {
        subTitle: "8. Legal Citations:",
        subContent:
          "This disclaimer is subject to relevant legal frameworks governing decentralized applications and blockchain transactions.",
      },
      {
        subTitle: "Last Updated:",
        subContent: "January 2023",
      },
    ],
  },
];
