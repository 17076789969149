import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import { privacyPolicyData } from "./data";

const TermsAndService = () => {
  return (
    <Box
      sx={{
        backgroundColor: "black",
        zIndex: 1000,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: {
          lg: "20px 35px",
          md: "20px 35px",
          sm: "10px 25px",
          xs: "5px 15px",
        },
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        minHeight: "85vh",
      }}
    >
      <Box textAlign="center" p={2}>
        <Typography
          sx={{ color: "#C7D921", fontSize: "34px", fontWeight: 700 }}
        >
          Terms And Services
        </Typography>
      </Box>
      <List sx={{ display: "flex", flexDirection: "column", padding: 0 }}>
        {privacyPolicyData.map((item, index) => {
          if (item.subItems) {
            return (
              <List key={index}>
                <ListItem sx={{}}>
                  <ListItemText
                    primary={item.title}
                    secondary={item.content}
                    sx={{
                      "& .MuiTypography-root": {
                        fontSize: "20px !important",
                        color: "white",
                        fontWeight: 700 + "!important",
                      },
                      "& .MuiTypography-body2": {
                        fontSize: "16px !important",
                        color: "white",
                        fontWeight: 400 + "!important",
                        paddingLeft: "20px",
                      },
                    }}
                  />
                </ListItem>
                {item.subItems.map((subItem, index) => (
                  <List key={index}>
                    <ListItem>
                      <ListItemText
                        primary={subItem.subTitle}
                        secondary={subItem.subContent}
                        sx={{
                          "& .MuiTypography-root": {
                            fontSize: "20px !important",
                            color: "white",
                            fontWeight: 700 + "!important",
                          },
                          "& .MuiTypography-body2": {
                            fontSize: "16px !important",
                            color: "white",
                            fontWeight: 400 + "!important",
                            paddingLeft: "20px",
                          },
                        }}
                      />
                    </ListItem>
                  </List>
                ))}
              </List>
            );
          } else {
            return (
              <ListItem key={index}>
                <ListItemText
                  sx={{
                    "& .MuiTypography-root": {
                      fontSize: "20px !important",
                      color: "white",
                      fontWeight: 700 + "!important",
                    },
                    "& .MuiTypography-body2": {
                      fontSize: "16px !important",
                      color: "white",
                      fontWeight: 400 + "!important",
                      paddingLeft: "20px",
                    },
                  }}
                  primary={item.title}
                  secondary={item.content}
                />
              </ListItem>
            );
          }
        })}
        <Box pb={2}>
          <Typography
            sx={{
              fontSize: "17px",
              fontWeight: 500 + "!important",
              color: "#fff",
            }}
          >
            By accessing or using the Platform, you acknowledge that you have
            read, understood, and agreed to the terms of the Terms of Service,
            Privacy Policy, and Legal Disclaimer. If you do not agree with any
            part of these terms, please refrain from using the Platform.
          </Typography>
        </Box>
      </List>
    </Box>
  );
};

export default TermsAndService;
